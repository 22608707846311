const activate = require('./containers/activate.json');
const deleteuser = require('./containers/deleteuser.json');
const deleteusersuccess = require('./containers/deleteusersuccess.json');
const endregistersuccess = require('./containers/endregistersuccess.json');
const home = require('./containers/home.json');
const project = require('./containers/project.json');
const projects = require('./containers/projects.json');
const projectsettings = require('./containers/projectsettings.json');
const registersuccess = require('./containers/registersuccess.json');
const usersettings = require('./containers/usersettings.json');

const containers = {
  activate,
  deleteuser,
  deleteusersuccess,
  endregistersuccess,
  home,
  project,
  projects,
  projectsettings,
  registersuccess,
  usersettings
}

export default containers;
