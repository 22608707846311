/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import Textpage from '../../components/Textpage/Textpage';
import Cookies from 'universal-cookie';
import de from '!raw-loader!../../markdown/Help/de.md';
import en from '!raw-loader!../../markdown/Help/en.md';

const Help = () => {
  const cookies = new Cookies();
  let language = cookies.get('language');
  if(!language) {
    language = 'de';
  }
  const md = {
    de,
    en
  };

  const styles = require('./Help.module.scss');
  // const input = '# This is a header\n\nAnd this is a paragraph';
  return (
    <div className={styles.dataprotection}>
      <Textpage md={md[language]}/>
    </div>
  );
};

export default (Help);
