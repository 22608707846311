import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
// import { ReactComponent as Logo } from '../../images/logo.svg';

const DeleteUserSuccess = ({ t, i18n }) => {
  // const { t, i18n } = useTranslation();
  const styles = require('./DeleteUserSuccess.module.scss');
  return (
    <div className={styles.deleteusersuccess}>
      <VpContainer centered>
        <div className={`${styles.row}`}>
          <div className={styles['col-12']}>
            <h1 className={styles.center}>{t('containers.deleteusersuccess.head')}</h1>
            <p className={`${styles.big} ${styles.center} ${styles.intro}`}>
              {t('containers.deleteusersuccess.text')}
            </p>
          </div>
        </div>
      </VpContainer>
    </div>
  );
};

export default (DeleteUserSuccess);
