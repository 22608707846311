import React from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Button from '../../Form/Button';

export default class ConfirmModal extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      text: PropTypes.string,
      titleSaving: PropTypes.string,
      isOpen: PropTypes.bool,
      closeAction: PropTypes.func,
      confirmAction: PropTypes.func,
      saving: PropTypes.bool,
      saveError: PropTypes.any,
      label: PropTypes.string,
      confirmLabel: PropTypes.string,
      canchelLabel: PropTypes.string
    };

    static defaultProps= {
      title: 'Modal-Title',
      titleSaving: 'titleSaving',
      text: 'Modal-Text',
      isOpen: true,
      closeAction: () => {},
      confirmAction: () => {},
      saving: false,
      saveError: false,
      label: 'Bitte eingeben',
      confirmLabel: 'Projekt Hinzufügen',
      canchelLabel: 'Abbrechen'
    };

    constructor(props) {
        super(props);
        this.state = {
          Name: '',
        };
        this.changeData = this.changeData.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentWillReceiveProps(props) {
      if(!props.saveError && !props.saving) {
        this.setState({Name: ''});
      }
    }

    // static getDerivedStateFromProps(props, state) {
    //   if(!props.saveError && !props.saving) {
    //     return {Name: ''};
    //   }
    // }

    changeData(target) {
      this.setState({
        Name: target.value
      });
    }

    confirm(event) {
      event.preventDefault();
      const { confirmAction } = this.props;
      confirmAction();
    }

    render() {
        const styles = require('./modal.scss');
        const { title, titleSaving, text, isOpen, closeAction, saving, confirmLabel, canchelLabel } = this.props;

        return (
          <div className={styles.inputmodal}>
            <Modal
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick
              onRequestClose={closeAction}
              isOpen={isOpen}
              contentLabel="Example Modal"
              className={'Modal'}
              overlayClassName={'Overlay'}
              ariaHideApp={false}
            >
              <form>
                {saving ?
                  <h2 className="modalTitle">{title} ({titleSaving})</h2>
                  :
                  <h2 className="modalTitle">{title}</h2>
                }
                <div className="modalBody">
                  <div className="modalText">{text}</div>
                </div>
                <div className="modalButtonBar">
                  <Button focus size="s" disabled={saving} onClick={closeAction}>{canchelLabel}</Button><Button size="s" disabled={saving} onClick={this.confirm}>{confirmLabel}</Button>
                </div>
              </form>
            </Modal>
          </div>
        );
    }
}
