import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Textarea from '../Form/Textarea';
import CopyBar from '../SnippetInputs/CopyBar';
import { withRouter } from "react-router";

export default withRouter(class Description extends Component {
  static propTypes = {
    service: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    warningInfo: PropTypes.object.isRequired,
    onLeave: PropTypes.func,
    label: PropTypes.string,
    descriptionInputRef: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    setGPT: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    projectState: PropTypes.object.isRequired,
    maxLength: PropTypes.object.isRequired,
  };
  static get defaultProps() {
    return {
      service: 'google',
      value: '',
      onLeave: () => {},
      label: '',
      setGPT: false
    };
  }

  render() {
    const styles = require('./Description.module.scss');

    const { descriptionInputRef, setGPT, projectState, maxLength } = this.props
    const {loadingFromGpt, device, service} = projectState;


    return (
      <div className={`${styles.title}`}>
        <Textarea loadingFromGpt={loadingFromGpt === 'description' ? true : false } {...this.props} textareaRef={descriptionInputRef} withprogress size="s" fullWidth name="description"/>
        <CopyBar loadingFromGpt={loadingFromGpt === 'description' ? true : false }  {...this.props} setGPT={() => setGPT('description', maxLength[device][service].description)}/>
      </div>
    );
  }
});
