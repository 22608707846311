import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Extern } from '../../images/icons/link-extern-icon.svg';

const styles = require('./Url.module.scss');

const Url = ({ t, url, icon }) => {
  try {
    const Url = new URL(url);
    return(
      <div className={styles.url + ' ' + styles.valid}><a href={url} target="_blank" rel="noopener noreferrer"><span className={styles.base}>{Url.protocol + '//' + Url.host}</span><span className={styles.path}>{Url.pathname}</span>{icon && <Extern />}</a></div>
    )
  } catch (_) {
    return(
      <div className={styles.url + ' ' + styles.invalid} title={t('components.url.invalid')}>{url}</div>
    )
  }
};

Url.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.bool,
  t: PropTypes.func.isRequired
};

Url.defaultProps = {
  url: 'https://www.wngmn.de/index.php',
  icon: false
};

export default Url;
