import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Sectionhead from '../../components/Htmlelements/Sectionhead';
import Button from '../Form/Button';
import Input from '../Form/Input';
import { withRouter } from "react-router";
// import { withTranslation } from 'react-i18next';
import login from '../../api/login.js';
import validator from 'validator';
import apiError from '../../utils/apierrors.js';

export default withRouter(class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      error: false,
      loading: false,
    };
    this.changeData = this.changeData.bind(this);
    this.login = this.login.bind(this);
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    this.validate = this.validate.bind(this);
    this.logout = this.logout.bind(this);

  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  login(event) {
    const { formData } = this.state;
    this.setState({loading: false});
    login(formData.email, formData.password, this.error, this.success);
    event.preventDefault();
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove('authtoken', { path: '/' });
    const { history } = this.props;
    history.push("/");
  }

  success() {
    const { history } = this.props;
    this.setState({
      error: false,
      loading: false
    });
    history.push("/projects");
  }

  error(error) {
    this.setState({
      error: error.response.data,
      loading: false
    });
  }

  changeData(target, isValid = null) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    newData.quality = true;
    if(isValid === false) {
      newData.quality = false;
    }
    newData.error = this.validate(newData);
    this.setState({
      formData: newData,
    });
  }

  validate(formData){
    // const {quality, formData} = this.state;
    let error = false;
    if (!validator.isEmail(formData.email)) {
      error = 'Bitte gültige E-Mail eingeben!';
    }
    return error;
  }

  render() {
    const styles = require('./Register.module.scss');

    const { formData, loading, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();

    return (
      <div className={styles.register}>
        {cookies.get('authtoken') ?
          <div>
            <Button style={{'float': 'right'}} fullWidth disabled={loading} onClick={this.logout} size="s">{t('components.general.buttons.logout')}</Button>
          </div>
          :
          <form onSubmit={this.register} className={styles.registerform}>
            <Sectionhead>{t('components.general.buttons.login')}</Sectionhead>
            <Input label={t('components.login.labels.email')} size="xxl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="email" value={formData.email}/>
            <Input type="password" label={t('components.login.labels.passwort')} size="xxl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="password" value={formData.password}/>
            <Button style={{'float': 'right'}} fullWidth disabled={formData.error ? true : false} onClick={this.login} size="l">{t('components.general.buttons.login')}</Button>
            {formData.error && <span className={styles.error}>{formData.error}</span>}
          </form>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
