/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import Textpage from '../../components/Textpage/Textpage';
import Cookies from 'universal-cookie';
import de from '!raw-loader!../../markdown/Agb/de.md'
import en from '!raw-loader!../../markdown/Agb/en.md'

const Agb = () => {

  const cookies = new Cookies();
  let language = cookies.get('language');
  if(!language) {
    language = 'de';
  }
  const md = {
    de,
    en
  };

  const styles = require('./Agb.module.scss');
  return (
    <div className={styles.agb}>
      <Textpage md={md[language]}/>
    </div>
  );
};

export default (Agb);
