import React, { Component } from 'react';
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';

import Hint from '../Htmlelements/Hint';
import Sectionhead from '../Htmlelements/Sectionhead';
import Button from '../Form/Button';
import ButtonIcon from '../../components/Form/ButtonIcon';
import SnippetContainer from '../Snippets/SnippetContainer';
import Pagination from '../Htmlelements/Pagination';
import LengthCounter from '../SnippetInputs/LengthCounter';
import Quality from '../SnippetInputs/Quality';
import FeatureContainer from '../Container/FeatureContainer';
import Textblocks from '../Htmlelements/Textblocks';
import Specialcharacters from '../Htmlelements/Specialcharacters';
import Title from '../SnippetInputs/Title';
import Description from '../SnippetInputs/Description';
import SaveBar from '../../components/Htmlelements/SaveBar';
import SaveBarMobile from '../../components/Htmlelements/SaveBarMobile';

import { ReactComponent as Nav } from '../../images/buttons/nav.svg';


export default withRouter(class Edit extends Component {

  constructor(props) {
    super(props);

    const cookies = new Cookies();
    const frontendSettings = cookies.get('frontendSettings');
    let showUrl = true;
    if(frontendSettings) {
      showUrl = frontendSettings.showUrl;
    }

    this.state = {
      showUrl: showUrl,
      widthInPx: {
        title: 0,
        description: 0
      },
      visibleSnippet: 'preview'
    };
    this.onChangeUrlState = this.onChangeUrlState.bind(this);
    this.insertString = this.insertString.bind(this);
    this.changePxWidth = this.changePxWidth.bind(this);
    this.gotoSnippetList = this.gotoSnippetList.bind(this);
  }

  static propTypes = {
    snippets: PropTypes.array,
    saving: PropTypes.bool,
    loading: PropTypes.bool,
    inputModalToggle: PropTypes.func.isRequired,
    gotoSnippet: PropTypes.func.isRequired,
    setSnippet: PropTypes.func.isRequired,
    onLeave: PropTypes.func,
    snippetData: PropTypes.object,
    service: PropTypes.string,
    device: PropTypes.string,
    snippetIndex: PropTypes.number,
    progress: PropTypes.number,
    saveAndPrev: PropTypes.func.isRequired,
    saveAndNext: PropTypes.func.isRequired,
    saveAndSame: PropTypes.func.isRequired,
    onChangeState: PropTypes.func.isRequired,
    maxLength: PropTypes.object.isRequired,
    resetTitleDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    setGPT: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    refs: PropTypes.object.isRequired,
    currentInput: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    projectState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    snippets: [],
    saving: false,
    snippetData: {},
    service: 'google',
    device: 'desktop',
    snippetIndex: 0,
    loading: true,
    progress: 0,
    resetTitleDescription: false,
    setGPT: false,
    onLeave: () => {}
  };

  onChangeUrlState() {
    let { showUrl } = this.state;
    const cookies = new Cookies();

    cookies.set('frontendSettings', {showUrl: !showUrl}, { path: '/' });
    this.setState({showUrl: !showUrl});
  }

  insertString(text, fieldName) {
    if(fieldName !== ''){
      const { setSnippet, snippetData, currentInput } = this.props;
      const currentValue = snippetData[fieldName].text;
      const begin = currentValue.substring(0, currentInput.selectionStart);
      const end = currentValue.substring(currentInput.selectionEnd, currentValue.length);
      const newValue = begin + text + end;

      setSnippet({name: fieldName, value: newValue}, () => {
        currentInput.target.focus();
        currentInput.target.setSelectionRange(currentInput.selectionStart + text.length, currentInput.selectionStart + text.length);
      });
    }
  }

  changePxWidth(widthInPx) {
    this.setState({widthInPx});
  }

  changeVisibleSnippet(snippet) {
    let visibleSnippet = 'preview';
    if(snippet === 'actual'){
      visibleSnippet = 'actual';
    }
    this.setState({
      visibleSnippet
    })
  }

  gotoSnippetList() {
    const { history } = this.props;
    history.push("/snippets/" + this.props.match.params.id);
  }

  render() {
    const styles = require('./Edit.module.scss');

    const cookies = new Cookies();

    // const { formData, error, loading } = this.state;
    const { t, snippets, saving, inputModalToggle, snippetData, service, device, snippetIndex, setSnippet, progress, onChangeState, saveAndNext, saveAndPrev, saveAndSame, gotoSnippet, setService, setDevice, maxLength, resetTitleDescription, onLeave, refs, currentInput, setGPT } = this.props;
    let { showUrl, widthInPx, visibleSnippet } = this.state;
    return (
      <div className={styles.edit}>
        {snippets.length > 0 || !cookies.get('authtoken') ?
          <div>
            <div className={styles.row}>
              <div className={styles.snippetPreviewSwitcher}>
                <Button style={{'maxWidth': '160px', 'marginLeft': '10px', 'marginRight': '0', 'borderTopLeftRadius': '5px', 'borderTopRightRadius': '5px'}} disabled={visibleSnippet === 'preview' ? true : false} size="m" onClick={() => {this.changeVisibleSnippet('preview')}}>{t('components.edit.heads.preview')}</Button>
                <Button style={{'maxWidth': '160px', 'marginLeft': '5px', 'marginRight': '0', 'borderTopLeftRadius': '5px', 'borderTopRightRadius': '5px'}} disabled={visibleSnippet === 'preview' ? false : true} size="m" onClick={() => {this.changeVisibleSnippet('actual')}}>{t('components.edit.heads.actual')}</Button>
                {cookies.get('authtoken') &&
                  <ButtonIcon style={{'position': 'absolute', 'right': '0px', 'top': '4px'}} size="s" title={t('components.savebar.buttons.back')} onClick={this.gotoSnippetList} disabled={saving}><Nav /></ButtonIcon>
                }
              </div>
              <div className={visibleSnippet === 'preview' ? styles['col-6'] + ' ' + styles['col-m-12'] : styles['col-6'] + ' ' + styles['col-m-12'] + ' ' + styles.hiddenSmallerM}>
                <Sectionhead className={styles.hiddenSmallerM}>{t('components.edit.heads.preview')}</Sectionhead>
                <SnippetContainer t={t} changePxWidth={this.changePxWidth} showUrl={showUrl} data={snippetData} service={service} onChangeUrlState={this.onChangeUrlState} onClickServices={setService} onClickDevice={setDevice} device={device} tabbed={true} />
              </div>
              <div className={visibleSnippet === 'actual' ? styles['col-6'] + ' ' + styles['col-m-12'] : styles['col-6'] + ' ' + styles['col-m-12'] + ' ' + styles.hiddenSmallerM}>
                {cookies.get('authtoken') &&
                  <Pagination of={t('components.edit.pagination.of')} className={styles.pagination} label={t('components.edit.pagination.label')} pageOptions={snippets} gotoPage={(nextIndex) => gotoSnippet(snippets[nextIndex].ID, nextIndex)} canPreviousPage={snippetIndex > 0 ? true : false} canNextPage={snippetIndex+1 < snippets.length ? true : false} previousPage={() => gotoSnippet(snippets[snippetIndex-1].ID, snippetIndex-1)} pageIndex={snippetIndex} nextPage={() => gotoSnippet(snippets[snippetIndex+1].ID, snippetIndex+1)} pageCount={snippets.length} />
                }
                <Sectionhead className={styles.hiddenSmallerM}>{t('components.edit.heads.actual')}</Sectionhead>
                <SnippetContainer t={t} resetTitleDescription={resetTitleDescription} showUrl={showUrl} url={snippetData.URL} service={service} />
              </div>
            </div>
            <div className={visibleSnippet === 'actual' ? styles.row + ' ' + styles.hiddenSmallerM : styles.row}>
              <div className={styles['col-6'] + ' ' + styles['col-m-12']}>
                <Sectionhead>Title <Quality quality={snippetData.title.quality} /><LengthCounter label={t('components.edit.lengthcounter.label')} of={t('components.edit.lengthcounter.of')} width={widthInPx.title} warningLength={snippetData.title.warningLength} length={snippetData.title.text.length} tileMaxLength={maxLength[device][service].title}></LengthCounter></Sectionhead>
                <Title t={t} {...this.props} setGPT={setGPT} label={t('components.edit.title.label')} titleInputRef={refs.title} onLeave={onLeave} onChange={setSnippet} warningInfo={snippetData.title} value={snippetData.title.text}/>
                <Sectionhead>Description <Quality quality={snippetData.description.quality} /> <LengthCounter label={t('components.edit.lengthcounter.label')} of={t('components.edit.lengthcounter.of')} width={widthInPx.description} warningLength={snippetData.description.warningLength} length={snippetData.description.text.length} tileMaxLength={maxLength[device][service].description}></LengthCounter></Sectionhead>
                <Description t={t} {...this.props} setGPT={setGPT} label={t('components.edit.description.label')} descriptionInputRef={refs.description} onLeave={onLeave} onChange={setSnippet} warningInfo={snippetData.description} value={snippetData.description.text}/>
              </div>
              <div className={styles['col-6'] + ' ' + styles['col-m-12']}>
                <Sectionhead className={styles.hiddenSmallerM}>&nbsp;</Sectionhead>
                <FeatureContainer headline={t('components.edit.heads.specialcharacters')}>
                  <Specialcharacters t={t} onClick={this.insertString} fieldName={currentInput.target.name} />
                </FeatureContainer>
                {cookies.get('authtoken') &&
                  <FeatureContainer headline={t('components.edit.heads.textmodules')}>
                    <Textblocks {...this.props} onClick={this.insertString} fieldName={currentInput.target.name} />
                  </FeatureContainer>
                }
              </div>
            </div>
            {cookies.get('authtoken') &&
              <div className={styles.row}>
                <div className={styles['col-12']}>
                  <SaveBar progress={progress} t={t} saving={saving} saveAndPrev={saveAndPrev} saveAndNext={saveAndNext} saveAndSame={saveAndSame} state={snippetData.Status} onChangeState={onChangeState}/>
                </div>
              </div>
            }
            {cookies.get('authtoken') &&
              <SaveBarMobile progress={progress} t={t} saving={saving} saveAndPrev={saveAndPrev} saveAndNext={saveAndNext} saveAndSame={saveAndSame} state={snippetData.Status} onChangeState={onChangeState}/>
            }
          </div>
          :
          <div className={styles.row}>
            <div className={styles['col-6']}>
              <Sectionhead>{t('components.edit.empty.head')}</Sectionhead>
              <Hint>{t('components.edit.empty.text')}</Hint>
              <Button style={{'marginLeft': '10px'}} disabled={saving} size="m" onClick={inputModalToggle}>{t('components.edit.empty.button')}</Button>
            </div>
          </div>
        }
      </div>
    );
  }
});
