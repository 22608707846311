import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Select extends Component {
  static propTypes = {
    label: PropTypes.string,
    showlabel: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onLeave: PropTypes.func,
    validators: PropTypes.array,
    showValidation: PropTypes.bool,
    labeltop: PropTypes.bool,
    type: PropTypes.string,
    data: PropTypes.object,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    warningInfo: PropTypes.object,
    holderstyles: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
  };
  static get defaultProps() {
    return {
      label: 'Foo',
      showlabel: false,
      name: 'foo',
      value: '',
      validators: [],
      showValidation: false,
      labeltop: false,
      type: 'text',
      data: {},
      size: 's',
      fullWidth: false,
      warningInfo: {},
      holderstyles: '',
      autoFocus: false,
      onLeave: () => {},
      inputRef: React.createRef(),
      disabled: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validate = this.validate.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showValidation === true) {
      const error = this.validate(this.state.value);
      if (error) {
        return {
          error,
          value: props.value
        };
      } else {
        return {
          error: false,
          value: props.value
        };
      }
    } else {

      return {
        value: props.value,
        error: props.error
      };
    }
  }

  validate(value) {
    const errors = [];
    this.props.validators.forEach(actValidator => {
      const newError = this.validators[actValidator](value);
      if (newError) {
        errors.push(newError);
      }
    });
    if (errors.length > 0) {
      const error = errors.join(' ');
      return error;
    }
    return false;
  }

  handleBlur(event) {
    let { onLeave } = this.props;
    const error = this.validate(event.target.value);

    const changedTarget = {};
    changedTarget.value = event.target.value;
    changedTarget.name = event.target.name;

    onLeave(event.target);

    if (error) {
      this.setState({
        error: false,
        value: changedTarget.value
      });
      this.props.onChange(changedTarget);
    } else {
      this.setState({
        error: false,
        value: changedTarget.value
      });
      this.props.onChange(changedTarget);
    }
  }

  handleChange(event) {
    const changedTarget = {};
    if (this.props.validators.includes('zip')) {
      changedTarget.value = event.target.value.trim();
    } else {
      changedTarget.value = event.target.value;
    }
    changedTarget.name = event.target.name;
    this.setState({
      value: changedTarget.value
    });
    this.props.onChange(changedTarget);
  }

  render() {
    const styles = require('./Select.module.scss');
    const { holderstyles, size, name, label, showlabel, labeltop, fullWidth, autoFocus, inputRef, disabled, value } = this.props;
    return (
      <div className={`${holderstyles} ${styles.row} ${styles.select}`}>
        {showlabel && (
          <div>
            <label htmlFor={name}>{label}:</label>
          </div>
        )}
        <div>
          {labeltop && (
            <label htmlFor={name} className={styles.labeltop}>
              {label}:
            </label>
          )}
          <select disabled={disabled} ref={inputRef} autoFocus={autoFocus} className={fullWidth ? styles[size] + ' ' + styles.fullWidth : styles[size]} name={name} onChange={this.handleChange} onBlur={this.handleBlur}>
            {this.props.options.map((option, index) => {
              return (
                <option key={index} value={option.value} selected={value === option.value ? true : false}>
                  {option.label}
                </option>
              );
            })}
          </select>
          {this.state.error && <span className={`${styles.error}`}>{this.state.error}</span>}
        </div>
      </div>
    );
  }
}
