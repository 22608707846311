import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Form/Button';
import Option from '../Form/Option';

const styles = require('./SaveBar.module.scss');

const SaveBar = ({ t, state, onChangeState, saveAndNext, saveAndPrev, saveAndSame, saving, progress }) => (
  <div className={styles.savebar}>
    <div className={styles.inner}>
      <div className={styles.row}>
        <div className={styles['col-3']}>
          <Button size="l" onClick={saveAndPrev} disabled={saving}>{t('components.savebar.buttons.back')}</Button>
        </div>
        <div className={styles['col-6'] + ' ' + styles.center + ' ' + styles.status}>
          <label>{t('components.savebar.state')}:</label>
          <Option name="draft" onChange={onChangeState} active={state === 'draft' ? true : false} label={t('components.savebar.fields.draft')}/>
          <Option name="review" onChange={onChangeState} active={state === 'review' ? true : false} label={t('components.savebar.fields.review')}/>
          <Option disabled={saving} name="ready" onChange={onChangeState} active={state === 'ready' ? true : false} label={t('components.savebar.fields.ready')}/>
        </div>
        <div className={styles['col-3'] + ' ' + styles.right}>
          <Button disabled={saving} size="l" onClick={saveAndSame}>{t('components.savebar.buttons.save')}</Button>
          <Button disabled={saving} size="l" onClick={saveAndNext}>{t('components.savebar.buttons.next')}</Button>
        </div>
      </div>
    </div>
    <div className={styles.progress}>
      <div className={styles.progressInner} style={{width: progress + '%'}}>{progress.toFixed(2).replace('.', ',')}%</div>
    </div>
  </div>
);

SaveBar.propTypes = {
  // children: PropTypes.string.isRequired,
  state: PropTypes.string,
  onChangeState: PropTypes.func.isRequired,
  saveAndNext: PropTypes.func.isRequired,
  saveAndPrev: PropTypes.func.isRequired,
  saveAndSame: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  progress: PropTypes.number,
  t: PropTypes.func.isRequired
};

SaveBar.defaultProps = {
  state: 'ready',
  saving: false,
  progress: 0,
};

export default SaveBar;
