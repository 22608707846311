import React from 'react';
import PropTypes from 'prop-types';
// import Button from '../Form/Button';
// import Option from '../Form/Option';
import { ReactComponent as ArrowLine } from '../../../images/arrows/arrow_line.svg';
import { ReactComponent as Delete } from '../../../images/buttons/delete_small.svg';

const styles = require('./Textblock.module.scss');

const Textblock = ({ edit, onClick, deleteItem, textBlock, deleting, fieldName }) => (
  <div className={edit ? styles.textblock + ' ' + styles.edit : styles.textblock} onClick={() => {if(!edit){onClick(textBlock.Text, fieldName);}}}>
    {!edit && <ArrowLine />}{textBlock.Text}{edit && <button disabled={deleting} onClick={() => {deleteItem(textBlock.ID);}} className={styles.delete}><Delete /></button>}
  </div>
);

Textblock.propTypes = {
  fieldName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  deleteItem: PropTypes.func.isRequired,
  textBlock: PropTypes.object.isRequired,
  deleting: PropTypes.bool,
};

Textblock.defaultProps = {
  edit: false,
  deleting: false
};

export default Textblock;
