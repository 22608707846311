import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
import { withRouter } from "react-router";
import { withTranslation, Trans } from 'react-i18next';
import Endregister from '../../components/Htmlelements/Endregister';


class Activate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;
        const styles = require('./Activate.module.scss');
        return (
          <div className={styles.activate}>
            <VpContainer image centered>
                <div className={`${styles.row}`}>
                  <div className={`${styles.row}`}>
                    <div className={styles['col-12']}>
                      <h1 className={`${styles.big} ${styles.center} ${styles.intro}`}>
                        <Trans i18nKey="containers.activate.head" />
                      </h1>
                    </div>
                  </div>
                  <div className={`${styles.row}`}>
                    <div className={styles['col-4']}>
                      &nbsp;
                    </div>
                    <div className={styles['col-4']}>
                      <Endregister t={t} />
                    </div>
                  </div>
                </div>
            </VpContainer>
          </div>
        );
    }
};

export default withRouter(withTranslation()(Activate));
