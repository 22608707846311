import React from 'react';
import Cookies from 'universal-cookie';
import VpContainer from '../../components/Container/VpContainer';
import Usersettingsform from '../../components/Htmlelements/Usersettingsform';
import Leftnav from '../../components/Htmlelements/Leftnav';
import { withRouter } from "react-router";
const navpoints_de = require('../../json/nav.usersettings.de.json');
const navpoints_en = require('../../json/nav.usersettings.en.json');
const navpoints = {
  de: navpoints_de,
  en: navpoints_en,
}


export default withRouter(class Usersettings extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: false,
      };
      this.error = this.error.bind(this);
      this.success = this.success.bind(this);

      const cookies = new Cookies();
      this.language = cookies.get('language');
      if(!this.language) {
        this.language = 'de';
      }
    }

    static propTypes = {
    };

    success() {
      this.setState({
        error: false,
        loading: false
      });
    }

    error() {
      this.setState({
        error: true,
        loading: false
      });
    }

    render() {
        const styles = require('./Usersettings.module.scss');
        const { t } = this.props;

        return (
          <div className={styles.usersettings}>
            <VpContainer>
              <div className={`${styles.row}`}>
                <div className={styles['col-12']}>
                  <h1>{t('containers.usersettings.head')}</h1>
                </div>
              </div>
              <div className={`${styles.row}`}>
                <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                  <Leftnav navpoints={navpoints[this.language]} active={0} />
                </div>
                <div className={styles['col-9'] + ' ' + styles['col-m-12']}>
                  <Usersettingsform {...this.props} />
                </div>
              </div>
            </VpContainer>
          </div>
        );
    }
});
