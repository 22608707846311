import React from 'react';
import PropTypes from 'prop-types';
// import Button from '../Form/Button';
// import Option from '../Form/Option';

const styles = require('./Character.module.scss');

const Character = ({ char, onClick, onDoubleClick, fieldName, onMouseEnter, onMouseLeave }) => (
  <button onClick={() => onClick(char, fieldName)} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onDoubleClick={onDoubleClick} className={styles.character}>
    {char}
  </button>
);

Character.propTypes = {
  char: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
};

Character.defaultProps = {
  char: 'ready',
  onDoubleClick: () => {}
};

export default Character;
