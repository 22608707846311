import React from 'react';
import PropTypes from "prop-types";
import Container from '../../components/Container/Container';
import FormModal from '../../components/Htmlelements/Modals/FormModal';
import NewProjectForm from '../../components/Htmlelements/Modals/NewProjectForm';
import ConfirmModal from '../../components/Htmlelements/Modals/ConfirmModal';
import Sectionhead from '../../components/Htmlelements/Sectionhead';
import Loader from '../../components/Htmlelements/Loader';
import ButtonIcon from '../../components/Form/ButtonIcon';
import Button from '../../components/Form/Button';
import Table from '../../components/Htmlelements/Table';
import { withRouter } from "react-router";
import { ReactComponent as Delete } from '../../images/buttons/delete.svg';
import { ReactComponent as Settings } from '../../images/buttons/settings.svg';
import apiError from '../../utils/apierrors.js';
import {index, add, del} from '../../api/projects.js';

export default withRouter(class Projects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          projects: [],
          error: false,
          loading: true,
          inputModalIsOpen: false,
          createdHintOpen: false,
          idLastCreated: false,
          saving: false,
          saveError: false,
          originalProjects: [],
          pageNo: 0,
          sorting: {
            key: 'Title',
            direction: 'asc',
          },
          googletoken: '',
        };
        this.error = this.error.bind(this);
        this.success = this.success.bind(this);
        this.inputModalToggle = this.inputModalToggle.bind(this);
        this.handleCredentialResponse = this.handleCredentialResponse.bind(this);
        this.addProject = this.addProject.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.saveSuccess = this.saveSuccess.bind(this);
        this.saveError = this.saveError.bind(this);
        this.load = this.load.bind(this);
        this.gotoProject = this.gotoProject.bind(this);
        this.gotoSettings = this.gotoSettings.bind(this);
        this.changePage = this.changePage.bind(this);
        this.sort = this.sort.bind(this);
    }

    static propTypes = {
      // match: PropTypes.object.isRequired,
      // location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
      t: PropTypes.func.isRequired
    };

    componentDidMount() {
      this.load();
    }

    load() {
      index(this.success, this.error);
    }

    error(error) {
      let returnError = false;
      if(error.response) {
        returnError = error.response.data;
      }
      this.setState({
        error: returnError,
        loading: false
      });
    }

    createLine(data) {
      const { ID, Name } = data;
      return {
        ID,
        Name,
        // action: <div class="actions"><ButtonIcon size="s"><Edit /></ButtonIcon><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteProject(data.ID)}><Delete /></ButtonIcon></div>
        action: <div className="actions"><ButtonIcon size="s" title="Löschen" onClick={() => this.gotoSettings(data.ID)}><Settings /></ButtonIcon><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteProject(data.ID)}><Delete /></ButtonIcon></div>
      };
    }

    success(data) {
      const { sorting } = this.state;
      const projects = this.createNewDataFromOriginal(data, sorting.key)
      this.setState({
        originalProjects: data,
        error: false,
        loading: false,
        projects
      });
    }

    inputModalToggle(event) {
      event.preventDefault();
      const { inputModalIsOpen } = this.state;
      this.setState({
        inputModalIsOpen: !inputModalIsOpen
      });
    }

    addProject(val) {
      this.setState({
        saving: true
      });
      const URLs = val.URLs.split(',').map(item => item.trim());
      add({ ProjectName: val.Name, URLs, CSV: val.CSV}, this.saveSuccess, this.saveError);
    }

    deleteProject(ID) {
      del(ID, this.load, this.deleteError);
    }

    deleteError(error) {
      this.setState({
        error: error.response.data
      })
    }

    saveSuccess(data) {
      this.load();
      const { projects } = this.state;
      projects.push(this.createLine(data));
      this.setState({
        idLastCreated: data.ID,
        inputModalIsOpen: false,
        createdHintOpen: true,
        saving: false,
        saveError: false,
        projects
      });
    }

    saveError(data) {
      const { t } = this.props;
      this.setState({
        saveError: t('containers.projects.inputmodal.fields.Name.errors.duplicate'),
        saving: false
      });
    }

    gotoProject(ID) {
      const { history } = this.props;
      history.push('/project/' + ID);
    }

    gotoSettings(ID) {
      const { history } = this.props;
      history.push('/projectsettings/snippets/length/' + ID);
    }

    sort(event, sortKey) {
        const { sorting, originalProjects } = this.state;
        if(sorting.direction === 'desc') {
          sorting.direction = 'asc';
        }
        else{
          sorting.direction = 'desc';
        }
        sorting.key = sortKey;
        const projects = this.createNewDataFromOriginal(originalProjects, sortKey)
        this.setState({
          projects,
          sorting,
        })
    }

    changePage(pageNo) {
      this.setState({pageNo});
    }

    createNewDataFromOriginal(data, key) {
      const { sorting } = this.state;
      let newData = [];
      data.forEach(val => {
        newData.push(this.createLine(val));
      });
      if(sorting.direction === 'asc') {
        newData = newData.sort((a, b) => String(a[key]).localeCompare(b[key]));
      }
      else {
        newData = newData.sort((a, b) => String(b[key]).localeCompare(a[key]));
      }
      return newData;
    }

    handleCredentialResponse = (response) => {
      console.log("Encoded JWT ID token: ", response);
      // console.log('jwt_decode', JWT(response.credential));
      // this.setState({googletoken: response.credential});
      // Hier können Sie das ID-Token an Ihren Server senden
    };

    render() {
        const styles = require('./Projects.module.scss');
        const { loading, inputModalIsOpen, saving, saveError, error, pageNo, projects, sorting, createdHintOpen, idLastCreated } = this.state;
        const { t } = this.props;

        const cols = [
          {
            Header: 'ID',
            accessor: 'ID', // accessor is the "key" in the data
            sortType: 'basic',
            style: {width: '20%'}
          },
          {
            Header: 'Name',
            accessor: 'Name',
            sortType: 'basic',
            style: {width: '80%'}
          },
          {
            Header: '',
            accessor: 'action',
            disableSortBy: true,
            style: {'minWidth': '80px'}
          }
        ]

        return (
          <div className={styles.project}>
            <Container>
              <div className={styles.row}>
                <div className={styles['col-12']}>
                  <Sectionhead>{t('containers.projects.head')}</Sectionhead>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles['col-12']}>
                  {loading ?
                    <Loader style={{'marginLeft': '10px'}} />
                    :
                    <div>
                      <Table pageNo={pageNo} changePage={this.changePage} sort={this.sort} sorting={sorting} columns={cols} data={projects} rowclick={this.gotoProject}>
                        {1 === 2 && <Button size="m">Import</Button>}<Button size="m" onClick={this.inputModalToggle}>{t('containers.projects.buttons.new')}</Button>
                      </Table>
                    </div>
                  }
                </div>
              </div>
            </Container>
            <FormModal saveError={saveError} confirmLabel={t('containers.projects.inputmodal.confirmLabel')} canchelLabel={t('containers.projects.inputmodal.canchelLabel')} saving={saving} titleSaving={t('components.general.modals.titlesaving')} title={t('containers.projects.inputmodal.title')} text={t('containers.projects.inputmodal.text')} label={t('containers.projects.inputmodal.label')} isOpen={inputModalIsOpen} closeAction={this.inputModalToggle} confirmAction={this.addProject} values={{'Name': '', 'URLs': ''}}>
              <NewProjectForm t={t} error={saveError} fullWidth label={t('containers.projects.inputmodal.label')} size="s" holderstyles={styles.holderstyles} onChange={this.changeData}/>
            </FormModal>
            <ConfirmModal
              confirmAction={() => {
                this.setState({createdHintOpen: false}, () => {
                  this.gotoProject(idLastCreated);
                });
              }}
              closeAction={() => {this.setState({createdHintOpen: false, idLastCreated: false});}}
              title={t('containers.projects.confirmmodal.title')}
              titleSaving={t('containers.projects.confirmmodal.titlesaving')}
              text={t('containers.projects.confirmmodal.text')}
              confirmLabel={t('containers.projects.confirmmodal.confirmlabel')}
              canchelLabel={t('containers.projects.confirmmodal.canchellabel')}
              isOpen={createdHintOpen}
            />
            {apiError(error, this)}
          </div>
        );
    }
});
