import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Sectionhead.module.scss');

const Sectionhead = ({ children, className }) => (
  <h2 className={styles.section + ' ' + className}>{children}</h2>
);

Sectionhead.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  className: PropTypes.string
};

Sectionhead.defaultProps = {
  className: '',

};

export default Sectionhead;
