const axios = require('axios');

export function index(successCallback, errorCallback){
  axios.get('/v1/project/')
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export async function add(values, successCallback, errorCallback) {
  const { ProjectName, URLs, CSV } = values;

  const payload = {
    ProjectName: ProjectName,
    URLs: URLs.length > 0 && URLs[0] !== '' ? URLs : []
  };

  try {
    if (CSV) {
      const csvContent = await readCSVAsText(CSV);
      payload.CSV = csvContent;
    }

    const response = await axios.post('/v1/project/', payload);
    successCallback(response.data);
  } catch (error) {
    console.error("Error: ", error);
    errorCallback(error);
  }
}

function readCSVAsText(csvFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      resolve(event.target.result);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsText(csvFile);
  });
}


export function del(ID, successCallback, errorCallback) {
  axios.delete('/v1/project/' + ID, {

  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {index, add, del};
