import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Cookies from 'universal-cookie';
import IconLink from './IconLink';

import { ReactComponent as Usersettings } from '../../images/icons/usersettings.svg';
import { ReactComponent as Help } from '../../images/icons/help.svg';
import { ReactComponent as Projects } from '../../images/icons/projects.svg';

export default withRouter(class Headernav extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    t: PropTypes.func.isRequired,
  };
  static defaultProps = {
    active: false
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false
    };
    this.checkLoggedIn = this.checkLoggedIn.bind(this);
  }

  componentDidMount() {
    this.checkLoggedIn();
  }

  // componentWillReceiveProps(nextProps) {
  //   this.checkLoggedIn();
  // }
  static getDerivedStateFromProps(nextProps, prevState){
    const cookies = new Cookies();
    if(nextProps.history!==prevState.history){
      return { isLoggedIn: cookies.get('authtoken')};
    }
    else return null;
  }

  // componentDidUpdate(prevProps, prevState){
  //   const { active, history } = this.props;
  //   if(prevProps.active !== active || prevProps.history !== history){
  //     // this.setState({isLoggedIn: false});
  //   }
  // }

  checkLoggedIn() {
    const cookies = new Cookies();
    this.setState({
      isLoggedIn: cookies.get('authtoken')
    });
  }

  render() {
    const styles = require('./Headernav.module.scss');
    const { isLoggedIn } = this.state;
    const { active, t } = this.props;
    if(!isLoggedIn){
        return (<div/>);
    }
    return (
      <ul className={styles.headernav}>
        <li className={styles.hiddenBiggerM}><IconLink breakSmall  active={active === 'projects' ? true : false} icon={Projects} to="/projects">{t('components.header.projectselector.label')}</IconLink></li>
        <li><IconLink breakSmall active={active === 'usersettings' ? true : false} icon={Usersettings} to="/usersettings/password">{t('components.headernav.userdata')}</IconLink></li>
        <li><IconLink breakSmall  active={active === 'help' ? true : false} icon={Help} to="/help">{t('components.headernav.help')}</IconLink></li>
      </ul>
    );
  }
});
