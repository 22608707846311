import React from 'react';
import {
  Link
} from "react-router-dom";
import PropTypes from 'prop-types';

const styles = require('./Leftnav.module.scss');

const Leftnav = ({ navpoints, active, param }) => (
  <nav className={styles.leftnav}>
    {navpoints.map((point, i) =>
      <Link key={point.name} className={i === active ? styles.active : styles.inactive} to={param ? point.path + '/' + param : point.path}>{point.name}</Link>
    )}
  </nav>
);

Leftnav.propTypes = {
  // children: PropTypes.node.isRequired,
  navpoints: PropTypes.array.isRequired,
  active: PropTypes.number,
  param: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
};

Leftnav.defaultProps = {
  // children: PropTypes.node.isRequired,
  active: 0,
  param: false
};

export default Leftnav;
