const axios = require('axios');

const activate = (activationcode, password, errorCallback, successCallback) => {
  axios.put('/v1/user/me/activate/' + activationcode, {
    // Email: email,
    NewPassword: password
  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default activate;
