const axios = require('axios');

const del = (errorCallback, successCallback) => {
  axios.delete('/v1/user/me', {
  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default del;
