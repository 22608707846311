const axios = require('axios');
const _ = require('underscore');

export function load(ID, errorCallback, successCallback){
  axios.get('/v1/project/' + ID + '/config/')
  .then(function (response) {
    const overrided = defaultOverride(response.data);
    successCallback(overrided);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function defaultOverride(data){
  const defaultSettings = require('../json/defaultsettings.json');
  const dataJSON = JSON.parse(data);

  // Emoji-Einstellungen
  defaultSettings.snippets.emojis[0] = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.emojis) ? parseInt(dataJSON.config.snippets.emojis[0]) : defaultSettings.snippets.emojis[0];
  defaultSettings.snippets.emojis[1] = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.emojis) ? parseInt(dataJSON.config.snippets.emojis[1]) : defaultSettings.snippets.emojis[1];

  // CTA-RegEx
  defaultSettings.snippets.cta.regex = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.cta) ? dataJSON.config.snippets.cta.regex : defaultSettings.snippets.cta.regex;

  // GPT
  defaultSettings.snippets.gpt.apikey = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.gpt) ? dataJSON.config.snippets.gpt.apikey : defaultSettings.snippets.gpt.apikey;
  defaultSettings.snippets.gpt.userAgent = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.gpt) ? dataJSON.config.snippets.gpt.userAgent : defaultSettings.snippets.gpt.userAgent;
  defaultSettings.snippets.gpt.gptModel = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.gpt) ? dataJSON.config.snippets.gpt.gptModel : defaultSettings.snippets.gpt.gptModel;

  // Custom Emojis
  defaultSettings.snippets.customemojis = dataJSON.config && !_.isUndefined(dataJSON.config.snippets.customemojis) ? dataJSON.config.snippets.customemojis : defaultSettings.snippets.customemojis;

  // Snippetlängen
  const services = ['google', 'bing', 'yandex'];
  const devices = ['desktop', 'mobile'];
  const fields = ['title', 'description'];
  services.forEach((service) => {
    const { length } = defaultSettings.snippets;
    devices.forEach((device) => {
      fields.forEach((field) => {
        length[device][field][service] = dataJSON.config && dataJSON.config.snippets.length[device][field] ? parseInt(dataJSON.config.snippets.length[device][field][service]) : length[device][field][service];
      });
    });
  });
  console.log('defaultSettings', defaultSettings);
  return defaultSettings;
}

export function update(ID, config, errorCallback, successCallback){
  axios.put('/v1/project/' + ID + '/config/', {
    config
  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {update, load};
