import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class Option extends Component {
  static propTypes = {
    label: PropTypes.string,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
  };
  static defaultProps = {
    label: 'Foo',
    active: false,
    onChange: () => { alert('Clicked!'); },
    name: 'foo'
  };

  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const { onChange, name } = this.props;
    // const { active } = this.state;
    onChange(name);
    // this.setState({ active: !active });
  }

  render() {
    const { label } = this.props;
    const styles = require('./Option.module.scss');
    return (
      <button onClick={this.handleChange} className={this.props.active ? styles.active + ' ' + styles.option : styles.option}>{label}</button>
    );
  }
}
