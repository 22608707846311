import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Smiley1 } from '../../images/smileys/smiley_1.svg';
import { ReactComponent as Smiley2 } from '../../images/smileys/smiley_2.svg';
import { ReactComponent as Smiley3 } from '../../images/smileys/smiley_3.svg';
import { ReactComponent as Smiley4 } from '../../images/smileys/smiley_4.svg';
import { ReactComponent as Smiley5 } from '../../images/smileys/smiley_5.svg';

export default class Quality extends Component {
  static propTypes = {
    quality: PropTypes.number
  };
  static defaultProps = {
    quality: 5
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getQualityIcon() {
    const { quality } = this.props;
    switch (quality) {
      case 1:
        return (<Smiley1/>);
      case 2:
        return (<Smiley2/>);
      case 3:
        return (<Smiley3/>);
      case 4:
        return (<Smiley4/>);
      case 5:
        return (<Smiley5/>);
      default:
        return (<Smiley1/>);
    }
  }

  render() {
    const styles = require('./Quality.module.scss');

    return (
      <div className={styles.quality}>{this.getQualityIcon()}</div>
    );
  }
}
