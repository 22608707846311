import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import IconLink from '../Htmlelements/IconLink';
import Input from '../Form/Input';
import { ReactComponent as Usersettings } from '../../images/icons/usersettings.svg';
import { withRouter } from "react-router";
// import { withTranslation } from 'react-i18next';
import login from '../../api/login.js';
import apiError from '../../utils/apierrors.js';

export default withRouter(class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      error: false,
      loading: false
    };
    this.changeData = this.changeData.bind(this);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
    this.error = this.error.bind(this);
    this.success = this.success.bind(this);
  }

  static propTypes = {
    // match: PropTypes.object.isRequired,
    // location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  logout() {
    const cookies = new Cookies();
    cookies.remove('authtoken', { path: '/' });
    const { history } = this.props;
    history.push("/");
  }

  login(event) {
    const { formData } = this.state;
    this.setState({loading: false});
    login(formData.email, formData.password, this.error, this.success);
    event.preventDefault();
  }

  error(error) {
    this.setState({
      error: error.response.data,
      loading: false
    });
  }

  success() {
    const { history } = this.props;
    this.setState({
      error: false,
      loading: false
    });
    history.push("/projects");
  }

  changeData(target) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    this.setState({
      formData: newData
    });
  }

  render() {
    const styles = require('./Login.module.scss');

    const { formData, error, loading } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();

    return (
      <div className={styles.login}>
        {error && <span className={styles.error}>{t('components.login.error')}</span>}
        {cookies.get('authtoken') ?
          <Button style={{'float': 'right'}} disabled={loading} onClick={this.logout} size="s">{t('components.general.buttons.logout')}</Button>
          :
          <>
            <div className={styles.loginlink}><IconLink style={{'marginTop': '8px'}} active={false} icon={Usersettings} to="/login">{t('components.general.buttons.login')}</IconLink></div>
            <form onSubmit={this.login} className={styles.loginform}>
              <Input label={t('components.login.labels.email')} size="s" holderstyles={styles.holderstyles} onChange={this.changeData} name="email" value={formData.email}/>
              <Input type="password" label={t('components.login.labels.passwort')} size="s" holderstyles={styles.holderstyles} onChange={this.changeData} name="password" value={formData.password}/>
              <Button style={{'float': 'right'}} disabled={loading} onClick={this.login} size="s">{t('components.general.buttons.login')}</Button>
            </form>
          </>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
