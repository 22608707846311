const defaultOverride = require('./config.js');
const axios = require('axios');

export function index(ID, successCallback, errorCallback){
  axios.get('/v1/project/' + ID + '/emojis/')
  .then(function (response) {
    axios.get('/v1/project/' + ID + '/config/')
    .then(function (configresponse) {
      let overrided = {};
      if(response.data === null){
        overrided = defaultOverride.defaultOverride('{}');
      }
      else{
        overrided = defaultOverride.defaultOverride(configresponse.data);
      }
      successCallback(response.data, overrided.snippets.customemojis);  
    })
    .catch(function (error) {
      errorCallback(error);
    });
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function update(ID, Emojis, successCallback, errorCallback) {
  axios.post('/v1/project/' + ID + '/emojis/', {
    Emojis
  })
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function del(projectID, textID, successCallback, errorCallback) {
  // axios.delete('/v1/project/' + projectID + '/text/' + textID, {
  //
  // })
  // .then(function (response) {
  //   successCallback();
  // })
  // .catch(function (error) {
  //   errorCallback(error);
  // });
}

export default {update, del, index};
