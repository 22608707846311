import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import Loader from './Loader';
import Input from '../Form/Input';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import { withRouter } from "react-router";
// import { withTranslation } from 'react-i18next';
import { update, load } from '../../api/config.js';
import apiError from '../../utils/apierrors.js';
export default withRouter(class SnippetSettingsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        error: 'Bitte Eingaben vervollständigen',
      },
      error: false,
      saving: false,
      loading: true,
      defaultSettings: {}
    };
    this.services = ['google', 'bing', 'yandex'];
    this.changeData = this.changeData.bind(this);
    this.update = this.update.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.updateError = this.updateError.bind(this);
    this.load = this.load.bind(this);
    this.loadSuccess = this.loadSuccess.bind(this);
    this.loadError = this.loadError.bind(this);
    this.validate = this.validate.bind(this);
    this.errorModalToggle = this.errorModalToggle.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    projectID: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  load(){
    const { projectID } = this.props;
    load(projectID, this.loadError, this.loadSuccess);
  }

  componentDidMount(){
    this.load();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  loadSuccess(data){
    const { length } = data.snippets;

    const newFormData = {};
    this.services.forEach(service => {
      const ServiceCapitalized = this.capitalizeFirstLetter(service);
      newFormData['desktopTitle' + ServiceCapitalized] = length.desktop.title[service];
      newFormData['desktopDescription' + ServiceCapitalized] = length.desktop.description[service];
      newFormData['mobileTitle' + ServiceCapitalized] = length.mobile.title[service];
      newFormData['mobileDescription' + ServiceCapitalized] = length.mobile.description[service];
    });
    this.setState({
      formData: newFormData,
      loading: false,
      defaultSettings: data
    });
  }

  loadError(error){
    this.setState({
      loading: false,
      error: error.response.data
    });
  }

  update(event) {
    const { formData, defaultSettings } = this.state;
    const { projectID } = this.props;

    this.services.forEach(service => {
      const ServiceCapitalized = this.capitalizeFirstLetter(service);

      defaultSettings.snippets.length.desktop.title[service] = formData['desktopTitle' + ServiceCapitalized];
      defaultSettings.snippets.length.desktop.description[service] = formData['desktopDescription' + ServiceCapitalized];
      defaultSettings.snippets.length.mobile.title[service] = formData['mobileTitle' + ServiceCapitalized];
      defaultSettings.snippets.length.mobile.description[service] = formData['mobileDescription' + ServiceCapitalized];

    });

    defaultSettings.snippets.length.desktop.title.google = formData.desktopTitleGoogle;
    defaultSettings.snippets.length.desktop.description.google = formData.desktopDescriptionGoogle;
    defaultSettings.snippets.length.desktop.title.bing = formData.desktopTitleBing;
    defaultSettings.snippets.length.desktop.description.bing = formData.desktopDescriptionBing;
    defaultSettings.snippets.length.desktop.title.yandex = formData.desktopTitleYandex;
    defaultSettings.snippets.length.desktop.description.yandex = formData.desktopDescriptionYandex;
    this.setState({saving: true});
    update(projectID, defaultSettings, this.updateError, this.updateSuccess);
    event.preventDefault();
  }

  updateSuccess() {
    this.setState({
      saving: false,
      error: false
    });
  }

  updateError(error) {
    this.setState({
      error: error.response.data,
      saving: false
    });
  }

  errorModalToggle(event) {
    event.preventDefault();
    const { errorModalIsOpen } = this.state;
    this.setState({
      errorModalIsOpen: !errorModalIsOpen,
      // error: false
    });
  }

  changeData(target, isValid = null) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    newData.error = this.validate(newData);
    this.setState({
      formData: newData,
    });
  }

  validate(formData){
    const { t } = this.props;
    let error = false;

    for (var field in formData) {
      if(field !== 'error'){
        if(String(parseInt(formData[field])) !== String(formData[field])){

          error = t('components.snippetsettingsform.errors.notempty');
        }
      }
    };
    return error;
  }

  render() {
    const styles = require('./SnippetSettingsForm.module.scss');

    const { formData, saving, loading, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();



    return (
      <div className={styles.generalsettings}>
        {!cookies.get('authtoken') ?
          <div>Du bist nicht eingeloggt!</div>
          :
          <form onSubmit={this.update} className={styles.generalsettingsform}>
            {loading ?
              <Loader style={{'marginLeft': '10px'}}/>
              :
              <div>
                <h2 className={styles.formhead}>{t('components.snippetsettingsform.heads.google')}</h2>
                <div className={`${styles.row}`}>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titledesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopTitleGoogle" value={formData.desktopTitleGoogle}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptiondesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopDescriptionGoogle" value={formData.desktopDescriptionGoogle}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titlemobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileTitleGoogle" value={formData.mobileTitleGoogle}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptionmobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileDescriptionGoogle" value={formData.mobileDescriptionGoogle}/>
                  </div>
                </div>
                <h2 className={styles.formhead}>{t('components.snippetsettingsform.heads.bing')}</h2>
                <div className={`${styles.row}`}>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titledesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopTitleBing" value={formData.desktopTitleBing}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptiondesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopDescriptionBing" value={formData.desktopDescriptionBing}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titlemobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileTitleBing" value={formData.mobileTitleBing}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptionmobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileDescriptionBing" value={formData.mobileDescriptionBing}/>
                  </div>
                </div>
                <h2 className={styles.formhead}>{t('components.snippetsettingsform.heads.yandex')}</h2>
                <div className={`${styles.row}`}>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titledesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopTitleYandex" value={formData.desktopTitleYandex}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptiondesktop')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="desktopDescriptionYandex" value={formData.desktopDescriptionYandex}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.titlemobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileTitleYandex" value={formData.mobileTitleYandex}/>
                  </div>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Input labeltop label={t('components.snippetsettingsform.fields.descriptionmobile')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="mobileDescriptionYandex" value={formData.mobileDescriptionYandex}/>
                  </div>
                </div>
                <Button style={{'float': 'right'}} disabled={formData.error ? true : false} onClick={this.update} size="m">{t('components.snippetsettingsform.buttons.save')}</Button>
                {formData.error && <span className={styles.error}>{formData.error}</span>}
              </div>
            }
          </form>
        }
        {saving && <SaveModal title="Speichere" titleSaving={t('components.general.modals.titlesaving')} isOpen={saving}/>}
        {apiError(error, this)}
      </div>
    );
  }
});
