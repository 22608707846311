const axios = require('axios');

export function view(URL, projectid, successCallback, errorCallback) {

  const url_regex = /^(http:\/\/|https:\/\/)/;
  if(!URL.match(url_regex)){
    URL = 'http://' + URL.trim()
  }
  axios.get('/v1/utils/snippet/' + projectid + '/' + encodeURIComponent(URL.trim()) + '/',
  { headers: { 'User-Agent': 'Wingmenbot' }  } )
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {view};
