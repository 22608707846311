import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
import Register from '../../components/Htmlelements/Register';
import { withTranslation, Trans } from 'react-i18next';


const Home = ({ t, i18n }) => {
  // const { t, i18n } = useTranslation();
  const styles = require('./Home.module.scss');
  // const history = useHistory();
  // history.push("/projects");
  // i18n.changeLanguage('en-US');
  return (
    <div className={styles.home}>
      <VpContainer image>
        <div className={`${styles.row}`}>
          <div className={styles['col-12']}>
            <p className={`${styles.big} ${styles.center} ${styles.intro}`}>
              <Trans i18nKey="containers.home.copy"></Trans>
            </p>
          </div>
        </div>
        <div className={`${styles.row}`}>
          <div className={styles['col-6'] + ' ' + styles['col-l-12']}>

            <ul className={styles.list}>
              <li>{t('containers.home.li.elem1')}</li>
              <li>{t('containers.home.li.elem2')}</li>
              <li>{t('containers.home.li.elem3')}</li>
              <li>{t('containers.home.li.elem4')}</li>
            </ul>
            <img alt="Snippettool" src="/screenshot.png" className={styles.screenshot} />
          </div>
          <div className={styles['col-6'] + ' ' + styles['col-l-12']}>
            <Register t={t} />
          </div>
        </div>
      </VpContainer>
    </div>
  );
};

export default withTranslation()(Home);
