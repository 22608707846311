import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
import { withTranslation, Trans } from 'react-i18next';
// import { ReactComponent as Logo } from '../../images/logo.svg';

const Registersuccess = ({ t, i18n }) => {
  // const { t, i18n } = useTranslation();
  const styles = require('./Registersuccess.module.scss');
  return (
    <div className={styles.registersuccess}>
      <VpContainer image centered>
        <div className={`${styles.row}`}>
          <div className={styles['col-12']}>
            <h1 className={styles.center}><Trans i18nKey={t('containers.registersuccess.head')} /></h1>
            <p className={`${styles.big} ${styles.center} ${styles.intro}`}>
              <Trans i18nKey={t('containers.registersuccess.text')} />
            </p>
          </div>
        </div>
      </VpContainer>
    </div>
  );
};

export default withTranslation()(Registersuccess);
