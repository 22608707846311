import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Loader.module.scss');

const Loader = ({ text, style }) => (
  <div className={styles.loader} style={style}>{text}</div>
);

Loader.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string
};

Loader.defaultProps = {
  style: {},
  text: 'Loader-Loading'
};

export default Loader;
