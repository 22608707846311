import React, { Component } from 'react';
// import PropTypes from "prop-types";
import Cookies from 'universal-cookie';

export default class LanguageSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: '',
        password: ''
      },
      error: false,
      loading: false
    };
    this.setLanguage = this.setLanguage.bind(this);
  }

  static propTypes = {
    // history: PropTypes.object.isRequired
  };

  setLanguage(event, lang) {
    const cookies = new Cookies();
    cookies.set('language', lang, { path: '/' });
    document.location.reload();
    event.preventDefault();
  }

  render() {
    const styles = require('./languageselector.module.scss');

    const languages = ['de', 'en'];

    const cookies = new Cookies();
    let userstatus = 'loggedout';
    if(cookies.get('authtoken')) {
      userstatus = 'loggedin';
    }
    let language = cookies.get('language');
    if(!language) {
      language = 'de';
      this.setLanguage(null, 'de');
    }

    return (
      <div className={styles.languageselector + ' ' + styles[userstatus] }>
        {languages.map((lang, i) =>
          <button key={lang} className={language === lang ? styles.active : styles.inactive} onClick={(e) => this.setLanguage(e, lang)}>{lang}</button>
        )}
      </div>
    );
  }
}
