const axios = require('axios');

// const apiEndpoint = 'http://localhost:3001/optimize';
const apiEndpoint = 'https://api.gpt-seo.bernardteske.de/optimize';

export function optimize(url, target, length, language, successCallback, errorCallback, apiKey, userAgent, gptModel){
  axios.post(apiEndpoint, {
    url,
    target,
    length,
    language,
    apiKey,
    userAgent,
    gptModel
  })
  .then(function (response) {
    successCallback(response.data, target);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {optimize};
