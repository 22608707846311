import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'universal-cookie';
// const de = require('./translations/de/de.js');
import de from './translations/de/de.js';
import en from './translations/en/en.js';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  }
};

const cookies = new Cookies();
let language = cookies.get('language');
if(!language) {
  language = 'de';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    detection: {},
    lng: language,
    resources,

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
