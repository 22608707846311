const axios = require('axios');

export function index(ID, successCallback, errorCallback){
  axios.get('/v1/project/' + ID + '/text/')
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function add(ID, Text, successCallback, errorCallback) {
  axios.post('/v1/project/' + ID + '/text/', {
    Name: Text,
    Text,
  })
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function del(projectID, textID, successCallback, errorCallback) {
  axios.delete('/v1/project/' + projectID + '/text/' + textID, {

  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {add, del, index};
