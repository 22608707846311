import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Form/Button';
import { withRouter } from "react-router";
import {encode} from 'html-entities';

// export default class CopyBar extends Component {
export default withRouter(class CopyBar extends Component {
  static propTypes = {
    value: PropTypes.string,
    t: PropTypes.func.isRequired,
    setGPT: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    loadingFromGpt: PropTypes.bool,
    projectState: PropTypes.object | PropTypes.bool,
  };
  static get defaultProps() {
    return {
      value: '',
      setGPT: () => (false),
      loadingFromGpt: false,
      projectState: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      copiedMasked: false
    };
  }

  didCopyReset() {
    this.setState({
      copied: false,
      copiedMasked: false
    });
  }

  doCopy(value, button, setGPT) {
    navigator.clipboard.writeText(value);
    if(button === 'copied') {
      this.setState({
        copied: true,
        copiedMasked: false
      });
    }
    else{
      this.setState({
        copied: false,
        copiedMasked: true
      });
    }
    window.setTimeout(() => {this.didCopyReset()}, 3000);
  }

  render() {
    const styles = require('./CopyBar.module.scss');
    const { value, t, setGPT, loadingFromGpt, projectState } = this.props;
    const { copied, copiedMasked } = this.state;

    console.log('projectState', projectState);

    return (
      <div className={styles.copybar}>
        <Button style={{'float': 'right', 'marginTop': '5px', 'marginRight': '0px'}} className={copied ? styles.buttonCopied : styles.buttonNotCopied} size="s" onClick={() => {this.doCopy(value, 'copied')}}>{copied ? t('components.copybar.buttons.done.copy') : t('components.copybar.buttons.default.copy')}</Button>
        <Button style={{'float': 'right', 'marginTop': '5px'}} size="s" className={copiedMasked ? styles.buttonCopied : styles.buttonNotCopied} onClick={() => {this.doCopy(encode(value, {mode: 'nonAscii'}), 'copiedMasked')}}>{copiedMasked ? t('components.copybar.buttons.done.copymasked') : t('components.copybar.buttons.default.copymasked')}</Button>        
        {projectState && projectState.config.gpt.apikey &&
          <>
          <Button loading={loadingFromGpt} style={{'float': 'right', 'marginTop': '5px'}} size="s" className={copiedMasked ? styles.buttonCopied : styles.buttonNotCopied} onClick={() => {setGPT()}}>{loadingFromGpt ? t('components.copybar.buttons.working.askgpt') : t('components.copybar.buttons.default.askgpt')}</Button>
          </>
        }
      </div>
    );
  }
});
