import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import Input from '../Form/Input';
import { withRouter } from "react-router";
import ReactPasswordStrength from 'react-password-strength';
// import { withTranslation } from 'react-i18next';
import activate from '../../api/activate.js';
import apiError from '../../utils/apierrors.js';

export default withRouter(class Endregister extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        password: '',
        passwordwdh: '',
        error: true,
        quality: false
      },
      error: false,
    };
    this.changeData = this.changeData.bind(this);
    this.activate = this.activate.bind(this);
    this.activateSuccess = this.activateSuccess.bind(this);
    this.activateError = this.activateError.bind(this);
    this.pwCallback = this.pwCallback.bind(this);
    this.validate = this.validate.bind(this);
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  activate(event) {
    const { match } = this.props
    const { formData } = this.state;
    activate(match.params.activationcode, formData.password, this.activateError, this.activateSuccess);
    event.preventDefault();
  }

  activateSuccess() {
    const { history } = this.props;
    history.push("/endregistersuccess");
  }

  activateError(error) {
    this.setState({
      error: error.response.data
    });
  }

  changeData(target, isValid = null) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    newData.quality = true;
    if(isValid === false) {
      newData.quality = false;
    }
    newData.error = this.validate(newData);
    this.setState({
      formData: newData,
    });
  }

  pwCallback(state, feedback) {
    const target = {
      name: 'password',
      value: state.password
    };
    this.changeData(target, state.isValid);
  }

  validate(formData){
    const { t } = this.props;
    let error = false;
    if(formData.password !== formData.passwordwdh) {
      error = t('components.general.passworderrors.notsame');
    }
    if(!formData.quality) {
      error = t('components.general.passworderrors.badquality');
    }
    if(formData.password === '') {
      error = true;
    }
    return error;
  }

  render() {
    const styles = require('./Register.module.scss');

    const { formData, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();

    return (
      <div className={styles.register}>
        {cookies.get('authtoken') ?
          <p>{t('containers.endregister.loggedin')}</p>
          :
          <form onSubmit={this.activate} className={styles.registerform}>
            <ReactPasswordStrength
              className={styles.passstrength}
              style={{ display: 'block' }}
              minLength={5}
              minScore={2}
              scoreWords={['👎', '👍', '👍👍', '💪', '💪💪']}
              tooShortWord={t('components.endregister.fields.passwordstrength.tooshortword')}
              changeCallback={this.pwCallback}
              inputProps={{ name: "password_input", autoComplete: "off", className: "form-control", placeholder: t('components.endregister.fields.passwordstrength.placeholder')} }
            />
            <Input type="password" label={t('components.endregister.fields.passwordwdh')} fullWidth size="xxl" holderstyles={styles.holderstyles} onChange={this.changeData} name="passwordwdh" value={formData.passwordwdh}/>
            <Button style={{'float': 'right'}} fullWidth disabled={formData.error ? true : false} onClick={this.activate} size="l">{t('components.endregister.buttons.register')}</Button>
            {formData.error && <span className={styles.error}>{formData.error}</span>}
          </form>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
