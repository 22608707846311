import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import Input from '../Form/Input';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import { withRouter } from "react-router";
import ReactPasswordStrength from 'react-password-strength';
import apiError from '../../utils/apierrors.js';
// import { withTranslation } from 'react-i18next';
import update from '../../api/userdata.js';
import validator from 'validator';

export default withRouter(class Usersettingsform extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        oldpassword: '',
        password: '',
        passwordwdh: '',
        error: this.props.t('components.usersettingsform.fields.defaulterror'),
        quality: false
      },
      error: false,
      saving: false,
    };
    this.changeData = this.changeData.bind(this);
    this.update = this.update.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.error = this.error.bind(this);
    this.pwCallback = this.pwCallback.bind(this);
    this.validate = this.validate.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  update(event) {
    const { formData } = this.state;
    this.setState({saving: true});
    update(formData.oldpassword, formData.password, this.error, this.updateSuccess);
    event.preventDefault();
  }

  updateSuccess() {
    //const { history } = this.props;
    // history.push("/registersuccess");
    this.setState({
      saving: false,
      error: false
    });
  }

  error(error) {
    this.setState({
      error: error.response.data,
      saving: false
    });
  }

  changeData(target, isValid = null) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    newData.quality = true;
    if(isValid === false) {
      newData.quality = false;
    }
    newData.error = this.validate(newData);
    this.setState({
      formData: newData,
    });
  }

  pwCallback(state, feedback) {
    const target = {
      name: 'password',
      value: state.password
    };
    this.changeData(target, state.isValid);
    // const { formData } = this.state;
    // formData.password = state.password;

    // if (state.isValid) {
    //   this.setState({passOk: true, formData});
    // } else {
    //   this.setState({
    //     passOk: false,
    //     error: 'Passwort nicht gut genug!',
    //     formData
    //   });
    // }
  }

  validate(formData){
    const { t } = this.props;
    // const {quality, formData} = this.state;
    let error = false;
    if (validator.isEmpty(formData.oldpassword)) {
      error = 'Bitte das bisherige Passwort eingeben!';
    }
    if(formData.password !== formData.passwordwdh) {
      error = t('components.general.passworderrors.notsame');
    }
    if(!formData.quality) {
      error = t('components.general.passworderrors.badquality');
    }
    return error;
  }

  render() {
    const styles = require('./Usersettingsform.module.scss');

    const { formData, saving, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();



    return (
      <div className={styles.usersettings}>
        {!cookies.get('authtoken') ?
          <div>{t('components.usersettingsform.loggedout')}</div>
          :
          <form onSubmit={this.update} className={styles.usersettingsform}>
            <Input label={t('components.usersettingsform.fields.actualpassword')} type="password" size="xxl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="oldpassword" value={formData.oldpassword}/>
            <ReactPasswordStrength
              className={styles.passstrength}
              style={{ display: 'block' }}
              minLength={5}
              minScore={2}
              scoreWords={['👎', '👍', '👍👍', '💪', '💪💪']}
              tooShortWord={t('components.usersettingsform.fields.passwordstrength.tooshort')}
              changeCallback={this.pwCallback}
              inputProps={{ name: "password_input", autoComplete: "off", className: "form-control", placeholder: t('components.usersettingsform.fields.passwordstrength.newpassword') }}
            />
            <Input type="password" label={t('components.usersettingsform.fields.passwordwdh')} fullWidth size="xxl" holderstyles={styles.holderstyles} onChange={this.changeData} name="passwordwdh" value={formData.passwordwdh}/>
            <Button style={{'float': 'right'}} fullWidth disabled={formData.error ? true : false} onClick={this.update} size="l">{t('components.usersettingsform.buttons.save')}</Button>
            {formData.error && <span className={styles.error}>{formData.error}</span>}
          </form>
        }
        {saving && <SaveModal titleSaving={t('components.general.modals.titlesaving')} title={t('components.usersettingsform.modals.title')} text={t('components.usersettingsform.modals.text')} isOpen={saving}/>}
        {apiError(error, this)}
      </div>
    );
  }
});
