import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
import Login from '../../components/Htmlelements/Login';
// import { ReactComponent as Logo } from '../../images/logo.svg';

const Registersuccess = ({ t, i18n }) => {
  // const { t, i18n } = useTranslation();
  const styles = require('./Endregistersuccess.module.scss');
  return (
    <div className={styles.endregistersuccess}>
      <VpContainer image centered>
        <div className={`${styles.row}`}>
          <div className={styles['col-12']}>
            <h1 className={styles.center}>{t('containers.endregistersuccess.head')}</h1>
            <p className={`${styles.big} ${styles.center} ${styles.intro}`}>
              {t('containers.endregistersuccess.text')}
            </p>
          </div>
        </div>
        <div className={`${styles.row}`}>
          <div className={styles['col-3'] + ' ' + styles['col-l-12']}>&nbsp;</div>
          <div className={styles['col-6'] + ' ' + styles['col-l-12']}>
            <Login t={t} />
          </div>
        </div>
      </VpContainer>
    </div>
  );
};

export default (Registersuccess);
