const snippetQuality = (value, percentWarning, config) => {
  const pointsReachable = 80; // Punkte in der QUalität erreichbar

  const pointsEmojis = getEmojiQuality(value, config);
  const pointsCta = checkCTA(value, config);
  const pointsLength = checkLength(percentWarning);
  const pointsReached = pointsEmojis + pointsCta + pointsLength;

  switch(true) {
    case (pointsReached < pointsReachable / 5): // Wenn weniger als 1/5 erreicht
      return 1;
    case (pointsReached < 2 * pointsReachable / 5): // Wenn weniger als 2/5 erreicht
      return 2;
    case (pointsReached < 3 * pointsReachable / 5): // Wenn weniger als 3/5 erreicht
      return 3;
    case (pointsReached < 4 * pointsReachable / 5): // Wenn weniger als 4/5 erreicht
      return 4;
    case (pointsReached >= pointsReachable * 0.9): // Wenn 90% erreicht
        return 5;
    default:
      return 1;
  }
}

// Emojis
const countEmojis = (text) => {
  // eslint-disable-next-line
  const res = ((text || '').match(/[^\x00-\x7F]/g) || []).length
  return res;
}
const getEmojiQuality = (text, config) => {
  const emojis = countEmojis(text);
  switch(true) {
    case (emojis <= config.emojis[0] && emojis > 0): // Wenn ein Emoji
      return 10;
    case (emojis > config.emojis[0] && emojis <= config.emojis[1]):  // // Wenn wenig Emojis
      return 5;
    case (emojis > config.emojis[1]): // Wenn mehr als erlaubt Emojis
      return 0;
    default:
      return 8;  // Wenn keine Emojis
  }
}

// Länge
const checkLength = percentWarning => {
  if(percentWarning <= 100) {
    return 50;
  }
  return 0;
}

// CTA
const checkCTA = (text, config) => {
  // const pattern = '((?:schnell|jetzt|hier|einfach(?!en)).*?(?:sparen|auswählen|vergleichen|erfahren|(?<!statt)finden|auswählen|informieren|entdecken|kaufen|bestellen|online(?!shop).*?(?:(?<!statt)finden|stöbern|auswählen|vergleichen|sparen|kaufen|bestellen|informieren|auswählen)?)|(?:entdecken?|kaufen|bestellen|auswählen|vergleichen|sparen|online(?!shop)|(?<!statt)finden) bei|mehr.*?(?:erfahren|kaufen|informieren)|(?:kaufen?|entdecken?|bestellen?) (?:sie|du))';
  // alert(config.cta.regex);
  var patt = new RegExp(config.cta.regex);
  var res = patt.exec(text);
  if(res) {
    return 20;  // 20 Punkte für ein CTA!
  }
  return 0;
}

export default snippetQuality;
