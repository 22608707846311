import React, {Suspense} from 'react';
import PropTypes from 'prop-types';
import {
  Link
} from "react-router-dom";
import { ReactComponent as Logo } from '../../images/logo.svg';
import Login from './Login';
import LanguageSelector from './LanguageSelector';
import Headernav from '../../components/Htmlelements/Headernav';
import ProjectSelector from '../Htmlelements/ProjectSelector';

const Header = ({active, t}) => {
  const styles = require('./Header.module.scss');
  return (
    <div className={styles.header}>
      <Link to="/" title={t('components.header.logo')}><Logo className={styles.logo}/></Link>
      <ProjectSelector t={t} label={t('components.header.projectselector.label')} active={active === 'projects' ? true : false} />
      <div className={styles.hiddenSmallerM}><Headernav t={t} active={active} /></div>
      <LanguageSelector />
      <Suspense fallback="loading">
      <Login t={t} />
      </Suspense>
    </div>
  );
};

Header.propTypes = {
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  t: PropTypes.func.isRequired
};

Header.defaultProps = {
  active: 'false',
};

export default Header;
