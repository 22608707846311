import React from 'react';
import PropTypes from 'prop-types';
import ArrowButton from '../Form/ArrowButton';

const styles = require('./Pagination.module.scss');

const Pagination = ({ of, label, pageOptions, gotoPage, canPreviousPage, previousPage, pageIndex, canNextPage, nextPage, pageCount, className, changePage }) => (
  <div className={styles.pagination + ' ' + className}>
    <ArrowButton direction="l" type="double" onClick={() => {gotoPage(0); changePage(0)}} disabled={!canPreviousPage} />
    <ArrowButton direction="l" onClick={() => {previousPage(); changePage(pageIndex - 1)}} disabled={!canPreviousPage} />
    <span>
      {label}
      <input
        type="text"
        size="2"
        value={pageIndex + 1}
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
        onClick={e => {e.target.select();}}
      />
      {of} {pageOptions.length}
    </span>
    <ArrowButton direction="r" onClick={() => {nextPage(); changePage(pageIndex + 1)}} disabled={!canNextPage} />
    <ArrowButton direction="r" type="double" onClick={() => {gotoPage(pageCount - 1); changePage(pageCount - 1)}} disabled={!canNextPage} />
  </div>
);

Pagination.propTypes = {
  pageOptions: PropTypes.array.isRequired,
  gotoPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  pageIndex: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  label: PropTypes.string,
  of: PropTypes.string,
  className: PropTypes.string,
  changePage: PropTypes.func,
};

Pagination.defaultProps = {
  canPreviousPage: false,
  canNextPage: false,
  label: 'Seite:',
  className: '',
  changePage: () => {},
  of: 'Pagination-Of',
  no: 'No-Of'
};

export default Pagination;
