import React from 'react';
import ErrorModal from '../components/Htmlelements/Modals/ErrorModal';
import Cookies from 'universal-cookie';
const apierrors = require('../json/apierrors.json');

const apiError = (error, that) => {
  
  if(error){
    let displayError = error;

    const cookies = new Cookies();
    let language = cookies.get('language');
    if(!language) {
      language = 'de';
    }

    if(apierrors[error.trim()]){
      if(apierrors[error.trim()][language]){
        displayError = apierrors[error.trim()][language];
      }
    }
    return (
      <ErrorModal closeLabel={that.props.t('components.general.buttons.ok')} text={"" + displayError} isOpen={true} closeAction={() => {that.setState({error: false});return false;}} />
    );
  }
  else{
    return false;
  }
}

export default apiError;
