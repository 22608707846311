import React from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Button from '../../Form/Button';

export default class InputModal extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      titleSaving: PropTypes.string,
      text: PropTypes.string,
      isOpen: PropTypes.bool,
      closeAction: PropTypes.func,
      confirmAction: PropTypes.func,
      saving: PropTypes.bool,
      confirmLabel: PropTypes.string,
      canchelLabel: PropTypes.string,
    };

    static defaultProps= {
      title: 'Modal-Title',
      titleSaving: 'Modal-Title-Saving',
      text: 'Modal-Text',
      isOpen: false,
      closeAction: () => {},
      confirmAction: () => {},
      saving: false,
      confirmLabel: 'Modal-Ok',
      canchelLabel: 'Modal-Canchel'
    };

    constructor(props) {
        super(props);
        this.state = {
          values: this.props.values,
        };
        this.changeData = this.changeData.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    changeData(target) {
      const { values } = this.state;
      values[target.name] = target.value;

      console.log('values', values);
      console.log('this.state.values', this.state.values);

      this.setState({
        values
      });
    }

    confirm(event) {
      event.preventDefault();
      const { confirmAction } = this.props;
      confirmAction(this.state.values);
    }

    render() {
        const styles = require('./modal.scss');
        const { title, titleSaving, text, isOpen, closeAction, saving, confirmLabel, canchelLabel, children } = this.props;
        const { values } = this.state;

        return (
          <div className={styles.inputmodal}>
            <Modal
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick
              onRequestClose={closeAction}
              isOpen={isOpen}
              contentLabel="Example Modal"
              className={'Modal'}
              overlayClassName={'Overlay'}
              ariaHideApp={false}
            >
              <form>
                {saving ?
                  <h2 className="modalTitle">{title} ({titleSaving})</h2>
                  :
                  <h2 className="modalTitle">{title}</h2>
                }
                <form className="modalBody" onSubmit={this.confirm}>
                  <div className="modalText">{text}</div>
                  {React.Children.map(children, child => {
                    return React.cloneElement(child, { parent: this, values: values, ...this.props });
                  })}
                </form>
                <div className="modalButtonBar">
                  <Button size="s" disabled={saving} onClick={closeAction}>{canchelLabel}</Button><Button focus={false} size="s" disabled={saving} onClick={this.confirm}>{confirmLabel}</Button>
                </div>
              </form>
            </Modal>
          </div>
        );
    }
}
