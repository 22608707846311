import React from 'react';
import { withRouter } from "react-router";
import Cookies from 'universal-cookie';
import Container from '../../components/Container/Container';
import InputModal from '../../components/Htmlelements/Modals/InputModal';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import Url from '../../components/Htmlelements/Url';
import ButtonIcon from '../../components/Form/ButtonIcon';
import Loader from '../../components/Htmlelements/Loader';
import Snippets from '../../components/Project/SnippetsMobile';
import { ReactComponent as Delete } from '../../images/buttons/delete.svg';
import apiError from '../../utils/apierrors.js';
import {index, add, del} from '../../api/snippets.js';
import {load} from '../../api/config.js';
import snippetQuality from '../../utils/snippetQuality.js';

export default withRouter(class Snippetslist extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
          config: {},
          originalSnippets: [],
          snippets: [],
          service: 'google',
          device: 'desktop',
          saving: false,
          inputModalIsOpen: false,
          addSnippetError: false,
          sorting: {
            key: 'Title',
            direction: 'asc',
          },
          loading: true,
      };
      this.load = this.load.bind(this);
      this.loadSuccess = this.loadSuccess.bind(this);
      this.loadError = this.loadError.bind(this);
      this.inputModalToggle = this.inputModalToggle.bind(this);
      this.addSnippet = this.addSnippet.bind(this);
      this.addSnippetError = this.addSnippetError.bind(this);
      this.addSnippetSuccess = this.addSnippetSuccess.bind(this);
      this.deleteError = this.deleteError.bind(this);
      this.createLine = this.createLine.bind(this);
      this.deleteSnippet = this.deleteSnippet.bind(this);
      this.gotoSnippet = this.gotoSnippet.bind(this);
      this.sort = this.sort.bind(this);
      this.changePage = this.changePage.bind(this);
      this.createNewDataFromOriginal = this.createNewDataFromOriginal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const cookies = new Cookies();
    if(this.props.match.params.id !== prevProps.match.params.id) {
      if(cookies.get('authtoken')) {
        this.load();
      }
    }
  }

  componentDidMount() {
    const cookies = new Cookies();
    this.refs = {
      'title': React.createRef(),
      'description': React.createRef()
    };
    if(cookies.get('authtoken')) {
      this.load();
    }
  }

  load() {
    load(this.props.match.params.id, () => {alert('error')}, (data) => {
      const { length } = data.snippets;

      const services = ['google', 'bing', 'yandex'];
      const devices = ['desktop', 'mobile'];
      const fields = ['title', 'description'];

      const maxLengthNew = {};

      devices.forEach(device => {
        maxLengthNew[device] = {};
        services.forEach(service => {
          maxLengthNew[device][service] = {};
          fields.forEach(field => {
            maxLengthNew[device][service][field] = length[device][field][service];
          });
        });
      });
      this.setState({
        maxLength: maxLengthNew,
        config: data.snippets
      });
      index(this.props.match.params.id, this.loadSuccess, this.loadError);
    });
  }

  loadSuccess(data) {
    const { sorting } = this.state;
    this.refs = {
      'title': React.createRef(),
      'description': React.createRef()
    }
    if (data === null) {
      this.setState({
        error: false,
        loading: false,
        originalSnippets: [],
        snippets: [],
      });
    }
    else{
      const snippets = this.createNewDataFromOriginal(data, sorting.key);
      this.setState({
        originalSnippets: data,
        error: false,
        loading: false,
        snippets,
        saving: false
      });
    }
  }

  loadError(error) {
    this.setState({
      error: error.response.data,
      loading: false
    });
  }

  createNewDataFromOriginal(data, key) {
    const { sorting } = this.state;
    let newData = [];
    data.forEach(val => {
      newData.push(this.createLine(val));
    });
    if(sorting.direction === 'asc') {
      newData = newData.sort((a, b) => String(a[key]).localeCompare(b[key]));
    }
    else {
      newData = newData.sort((a, b) => String(b[key]).localeCompare(a[key]));
    }
    return newData;
  }

  createLine(data) {
    const { config } = this.state;
    const { ID, Title, Description, Status, URL } = data;
    let newStatus = Status;
    if (Status === '') {
      newStatus = 'draft';
    }
    // Qualität berechnen
    const percentWarningTitle = this.getPercentWarning(Title, 'title');
    const qualitytitle = snippetQuality(Title, percentWarningTitle, config);
    const percentWarningDescription = this.getPercentWarning(Description, 'description');
    const qualitydescription = snippetQuality(Description, percentWarningDescription, config);

    return {
      ID,
      Title,
      URL,
      displayurl: <Url url={URL} t={this.props.t}/>,
      Description,
      qualitytitle,
      qualitydescription,
      Status: newStatus,
      // action: <div class="actions"><ButtonIcon size="s"><Edit /></ButtonIcon><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteSnippet(data.ID)}><Delete /></ButtonIcon></div>
      action: <div className="actions"><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteSnippet(ID)}><Delete /></ButtonIcon></div>
    };
  }

  getPercentWarning(text, targetname) { // Targetname ist title oder description
    let { maxLength, service, device } = this.state;
    const percentWarning = parseInt(100 / maxLength[device][service][targetname] * text.length);
    return percentWarning;
  }

  inputModalToggle(event) {
    event.preventDefault();
    const { inputModalIsOpen } = this.state;
    this.setState({
      inputModalIsOpen: !inputModalIsOpen
    });
  }

  addSnippet(val) {
    this.setState({
      saving: true
    });
    add(this.props.match.params.id, val, this.addSnippetSuccess, this.addSnippetError);
  }

  addSnippetError(data) {
    this.setState({
      addSnippetError: 'Snippet konnte nicht angelegt werden',
      saving: false
    });
  }

  addSnippetSuccess(data) {
    const { snippets } = this.state;
    snippets.push(this.createLine(data));
    this.setState({
      inputModalIsOpen: false,
      saving: false,
      addSnippetError: false,
      snippets
    });
    this.gotoSnippet(data.ID, data.ID);
  }

  // TODO: Überprüfe die delete-Funktion
  deleteSnippet(ID) {
    del(
      this.props.match.params.id,
      ID,
      this.load,
      () => this.setState({errorModalIsOpen: true}));
  }

  deleteError(error) {
    this.setState({
      error: error.response.data
    });
  }

  gotoSnippet(ID, index) {
    const { history } = this.props;
    history.push('/project/' + this.props.match.params.id + '/' + ID);
  }

  sort(event, sortKey) {
      const { sorting, originalSnippets } = this.state;
      if(sorting.direction === 'desc') {
        sorting.direction = 'asc';
      }
      else{
        sorting.direction = 'desc';
      }
      sorting.key = sortKey;
      const snippets = this.createNewDataFromOriginal(originalSnippets, sortKey)
      this.setState({
        snippets,
        sorting,
      })
  }

  changePage(pageNo) {
    this.setState({pageNo});
  }

  render() {
    const styles = require('./Snippets.module.scss');
    const { loading, snippetData, inputModalIsOpen, addSnippetError, saving, snippets, sorting, error} = this.state;
    const { t } = this.props;
    const cookies = new Cookies();
    return (
      <div className={styles.snippets}>
        {!cookies.get('authtoken') && snippetData.URL === '' ?
            <p>Not logged in!</p>
          :
          <div>
            {(!loading || !cookies.get('authtoken')) ?
              <Container>
                <Snippets
                  sorting={sorting}
                  snippets={snippets}
                  changePage={this.changePage}
                  sort={this.sort}
                  gotoSnippet={this.gotoSnippet}
                  inputModalToggle={this.inputModalToggle}
                  refresh={this.load}
                  t={t}
                />
                {snippets.length < 1 ?
                  <div />
                  :
                  <div />
                }
              </Container>
              :
              <Container>
                <div className={styles.row}>
                  <div className={styles['col-12']}>
                    <Loader style={{'marginLeft': '10px', 'marginTop': '50px'}} />
                  </div>
                </div>
              </Container>
            }
          </div>
        }
        <SaveModal title={t('containers.project.loggedin.savemodal.title')} titleSaving={t('components.general.modals.titlesaving')} text={t('containers.project.loggedin.savemodal.text')} isOpen={saving}/>
        <InputModal saveError={addSnippetError} saving={saving} titleSaving={t('components.general.modals.titlesaving')} title={t('containers.project.loggedin.inputmodal.title')} text={t('containers.project.loggedin.inputmodal.text')} label={t('containers.project.loggedin.inputmodal.label')} isOpen={inputModalIsOpen} closeAction={this.inputModalToggle} confirmAction={this.addSnippet} confirmLabel={t('containers.project.loggedin.inputmodal.confirmlabel')} canchelLabel={t('containers.project.loggedin.inputmodal.canchellabel')} />
        {apiError(error, this)}
      </div>
    );
  }
});
