import React from 'react';
import { withRouter } from "react-router";
import Cookies from 'universal-cookie';
import Container from '../../components/Container/Container';
import VpContainer from '../../components/Container/VpContainer';
import InputModal from '../../components/Htmlelements/Modals/InputModal';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import ConfirmModal from '../../components/Htmlelements/Modals/ConfirmModal';
import Urlinput from '../../components/Htmlelements/Urlinput';
import Url from '../../components/Htmlelements/Url';
import ButtonIcon from '../../components/Form/ButtonIcon';
import Loader from '../../components/Htmlelements/Loader';
import Edit from '../../components/Project/Edit';
import Snippets from '../../components/Project/Snippets';
import { ReactComponent as Delete } from '../../images/buttons/delete.svg';
import apiError from '../../utils/apierrors.js';
import {index, add, del, edit} from '../../api/snippets.js';
import {optimize} from '../../api/gpt.js';
import {load} from '../../api/config.js';
import snippetQuality from '../../utils/snippetQuality.js';

const getProgress = (data) => {
  let ready = 0;
  data.forEach(val => {
    if(val.Status === 'ready'){
      ready++;
    }
  });
  const progress = (ready / data.length) * 100;
  return progress;
}

export default withRouter(class Project extends React.Component {

    constructor(props) {
      super(props);
      const defaultSettings = require('../../json/defaultsettings.json');
      this.state = {
          config: defaultSettings.snippets,
          saveWarning: false,
          saveWarningIsOpen: false,
          currentInput: {
            target: '',
            selectionStart: '',
            selectionEnd: ''
          },
          progress: 0,
          originalSnippets: [],
          snippets: [],
          service: 'google',
          device: 'mobile',
          snippetIndex: 0,
          snippetData: {
            URL: '',
            title: {
              percentWarning: 1,
              warningLength: 'ok',
              text: '',
              quality: 1
            },
            description: {
              percentWarning: 1,
              warningLength: 'ok',
              text: '',
              quality: 1
            },
            Status: 'draft',
          },
          maxLength: {
            desktop: {
              google: {
                title: 58,
                description: 160
              },
              bing: {
                title: 60,
                description: 170
              },
              yandex: {
                title: 60,
                description: 150
              }
            },
            mobile: {
              google: {
                title: 65,
                description: 180
              },
              bing: {
                title: 60,
                description: 150
              },
              yandex: {
                title: 60,
                description: 150
              }
            }
          },
          saving: false,
          loadingFromGpt: false,
          inputModalIsOpen: false,
          addSnippetError: false,
          sorting: {
            key: 'Title',
            direction: 'asc',
          },
          loading: true,
      };
      this.setService = this.setService.bind(this);
      this.setDevice = this.setDevice.bind(this);
      this.setSnippet = this.setSnippet.bind(this);
      this.onChangeState = this.onChangeState.bind(this);
      this.load = this.load.bind(this);
      this.loadSuccess = this.loadSuccess.bind(this);
      this.loadError = this.loadError.bind(this);
      this.inputModalToggle = this.inputModalToggle.bind(this);
      this.addSnippet = this.addSnippet.bind(this);
      this.addSnippetError = this.addSnippetError.bind(this);
      this.addSnippetSuccess = this.addSnippetSuccess.bind(this);
      this.editSnippet = this.editSnippet.bind(this);
      this.editSnippetError = this.editSnippetError.bind(this);
      this.editSnippetSuccess = this.editSnippetSuccess.bind(this);
      this.gotoNextPrev = this.gotoNextPrev.bind(this);
      this.successNext = this.successNext.bind(this);
      this.successPrev = this.successPrev.bind(this);
      this.deleteError = this.deleteError.bind(this);
      this.successSame = this.successSame.bind(this);
      this.createLine = this.createLine.bind(this);
      this.deleteSnippet = this.deleteSnippet.bind(this);
      this.gotoSnippet = this.gotoSnippet.bind(this);
      this.setSnippetData = this.setSnippetData.bind(this);
      this.getSnippetById = this.getSnippetById.bind(this);
      this.saveAndNext = this.saveAndNext.bind(this);
      this.saveAndPrev = this.saveAndPrev.bind(this);
      this.onLeave = this.onLeave.bind(this);
      this.sort = this.sort.bind(this);
      this.changePage = this.changePage.bind(this);
      this.createNewDataFromOriginal = this.createNewDataFromOriginal.bind(this);
      this.resetTitleDescription = this.resetTitleDescription.bind(this);
      this.setGPT = this.setGPT.bind(this);
      this.setGPTSuccess = this.setGPTSuccess.bind(this);
      this.setGPTError = this.setGPTError.bind(this);
      this.setUrl = this.setUrl.bind(this);
      this.specialcharacters = this.specialcharacters.bind(this);
  }

  componentDidUpdate(prevProps) {
    const cookies = new Cookies();
    if(this.props.match.params.id !== prevProps.match.params.id) {
      if(cookies.get('authtoken')) {
        this.load();
      }
    }
  }

  componentDidMount() {
    const cookies = new Cookies();
    this.refs = {
      'title': React.createRef(),
      'description': React.createRef()
    };
    if(cookies.get('authtoken')) {
      this.load();
    }
  }

  load() {
    load(this.props.match.params.id, () => {alert('error')}, (data) => {
      const { length } = data.snippets;

      const services = ['google', 'bing', 'yandex'];
      const devices = ['desktop', 'mobile'];
      const fields = ['title', 'description'];

      const maxLengthNew = {};

      devices.forEach(device => {
        maxLengthNew[device] = {};
        services.forEach(service => {
          maxLengthNew[device][service] = {};
          fields.forEach(field => {
            maxLengthNew[device][service][field] = length[device][field][service];
          });
        });
      });
      this.setState({
        maxLength: maxLengthNew,
        config: data.snippets
      });
      index(this.props.match.params.id, this.loadSuccess, this.loadError);
    });
  }

  loadSuccess(data) {
    const { sorting } = this.state;
    const { history } = this.props;
    this.refs = {
      'title': React.createRef(),
      'description': React.createRef()
    }
    if (!this.props.match.params.snippet && data !== null) {
      history.push('/project/' + this.props.match.params.id + '/' + data[0]['ID']);
    }
    if (data === null) {
      this.setState({
        error: false,
        loading: false,
        originalSnippets: [],
        snippets: [],
      });
    }
    else{
      const snippets = this.createNewDataFromOriginal(data, sorting.key);
      const snippetById = this.getSnippetById(snippets, this.props.match.params.snippet);
      const snippetData = this.setSnippetData(snippetById.snippet);
      this.setState({
        originalSnippets: data,
        error: false,
        loading: false,
        snippets,
        snippetData,
        snippetIndex: snippetById.index,
        progress: getProgress(data),
        saving: false
      });
    }
  }

  loadError(error) {
    this.setState({
      error: error.response.data,
      loading: false
    });
  }

  createNewDataFromOriginal(data, key) {
    const { sorting } = this.state;
    let newData = [];
    data.forEach(val => {
      newData.push(this.createLine(val));
    });
    if(sorting.direction === 'asc') {
      newData = newData.sort((a, b) => String(a[key]).localeCompare(b[key]));
    }
    else {
      newData = newData.sort((a, b) => String(b[key]).localeCompare(a[key]));
    }
    return newData;
  }

  getSnippetById(data, ID) {
    let snippet;
    let index = 0;
    let i = 0;
    data.forEach(val => {
      if(String(val.ID) === String(ID)) {
        snippet = val;
        index = i;
      }
      i = i+1;
    });
    return {snippet, index};
  }

  saveAndNext() {
    this.editSnippet(1);
  }

  saveAndPrev() {
    this.editSnippet(-1);
  }

  editSnippet(direction) {
    const { snippetData } = this.state;
    const saveData = {
      URL: snippetData.URL,
      Title: snippetData.title.text,
      Description: snippetData.description.text,
      Status: snippetData.Status,
      Bookmarked: false
    }
    this.setState({
      saving: true
    });
    switch (direction) {
      case 1:
        edit(this.props.match.params.id, this.props.match.params.snippet, saveData, this.successNext, this.editSnippetError);
        break;
      case -1:
        edit(this.props.match.params.id, this.props.match.params.snippet, saveData, this.successPrev, this.editSnippetError);
        break;
      default:
        edit(this.props.match.params.id, this.props.match.params.snippet, saveData, this.successSame, this.editSnippetError);
    }
  }

  successNext(data){
    this.editSnippetSuccess(data, 1);
  }

  successPrev(data){
    this.editSnippetSuccess(data, -1);
  }

  successSame(data){
    this.editSnippetSuccess(data, 0);
  }

  editSnippetSuccess(data, direction) {
    this.load();
    const { snippets, snippetIndex } = this.state;
    if (direction === 0) {
      this.gotoSnippet(snippets[snippetIndex].ID, snippetIndex);
    }
    else {
      if(snippetIndex + direction >= snippets.length){
        this.gotoSnippet(snippets[0].ID, 0);
      }
      else{
        if(snippetIndex + direction <= 0){
          this.gotoSnippet(snippets[snippets.length - 1].ID, snippets.length- 1);
        }
        this.gotoSnippet(snippets[snippetIndex  + direction].ID, snippetIndex + direction);
      }
    }
  }

  gotoNextPrev(direction) {
    const { snippets, snippetIndex, saveWarning } = this.state;
    if(saveWarning){
      this.directionNextPrex = direction;
      this.setState({saveWarningIsOpen:saveWarning});
    }
    else{
      if(snippetIndex + direction >= snippets.length){
        this.gotoSnippet(snippets[0].ID, 0);
      }
      else{
        if(snippetIndex + direction < 0){
          this.gotoSnippet(snippets[snippets.length - 1].ID, snippets.length- 1);
        }
        else{
          this.gotoSnippet(snippets[snippetIndex  + direction].ID, snippetIndex + direction);
        }
      }
    }
  }

  editSnippetError(data) {
    this.setState({
      addSnippetError: 'Snippet konnte nicht gespeichert werden',
      saving: false
    });
  }

  createLine(data) {
    const { config } = this.state;
    const { ID, Title, Description, Status, URL } = data;
    let newStatus = Status;
    if (Status === '') {
      newStatus = 'draft';
    }
    // Qualität berechnen
    const percentWarningTitle = this.getPercentWarning(Title, 'title');
    const qualitytitle = snippetQuality(Title, percentWarningTitle, config);
    const percentWarningDescription = this.getPercentWarning(Description, 'description');
    const qualitydescription = snippetQuality(Description, percentWarningDescription, config);

    return {
      ID,
      Title,
      URL,
      displayurl: <Url url={URL} t={this.props.t}/>,
      Description,
      qualitytitle,
      qualitydescription,
      Status: newStatus,
      // action: <div class="actions"><ButtonIcon size="s"><Edit /></ButtonIcon><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteSnippet(data.ID)}><Delete /></ButtonIcon></div>
      action: <div className="actions"><ButtonIcon size="s" confirm title="Löschen" onClick={() => this.deleteSnippet(ID)}><Delete /></ButtonIcon></div>
    };
  }

  setService(service) {
    // --> this.setSnippetData(this.state.snippetData);
    const { snippetData } = this.state;
    snippetData.title = this.setWarningLength({name: 'title', value: snippetData.title.text}, service);
    snippetData.description = this.setWarningLength({name: 'description', value: snippetData.description.text}, service);
    this.setState({
      service,
      snippetData
    });
  }

  setDevice(device) {
    this.setState({device});
  }

  setSnippet(target, callback) {
    let { snippetData } = this.state;
    snippetData[target.name] = this.setWarningLength(target);
    this.setState({snippetData}, callback);
  }

  onLeave(target) {
    this.specialcharacters(target);
    this.setState({saveWarning: true});
    // const cookies = new Cookies();
    // if(cookies.get('authtoken')) {
    //   this.editSnippet(0);
    // }
  }

  getPercentWarning(text, targetname) { // Targetname ist title oder description
    let { maxLength, service, device } = this.state;
    const percentWarning = parseInt(100 / maxLength[device][service][targetname] * text.length);
    return percentWarning;
  }

  setWarningLength(target, newservice = false) {
    let { maxLength, service, device, config } = this.state;
    if(newservice) {
      service = newservice;
    }
    // const percentWarning = parseInt(100 / maxLength[device][service][target.name] * target.value.length);
    const percentWarning = this.getPercentWarning(target.value, target.name);
    const quality = snippetQuality(target.value, percentWarning, config);
    let warningLength = 'ok';
    if(percentWarning > 80) {
      warningLength = 'warning';
    }
    if(target.value.length > maxLength[device][service][target.name]) {
      warningLength = 'error';
    }
    return {percentWarning, warningLength, text: target.value, quality};
  }

  onChangeState(value) {
    const { snippetData } = this.state;
    snippetData.Status = value;
    this.setState({snippetData});
    this.onLeave();
  }

  setUrl(url) {
    let { snippetData } = this.state;
    snippetData.URL = url;
    this.setState({snippetData});
  }

  inputModalToggle(event) {
    event.preventDefault();
    const { inputModalIsOpen } = this.state;
    this.setState({
      inputModalIsOpen: !inputModalIsOpen
    });
  }

  addSnippet(val) {
    this.setState({
      saving: true
    });
    add(this.props.match.params.id, val, this.addSnippetSuccess, this.addSnippetError);
  }

  addSnippetError(data) {
    // const { projects } = this.state;
    this.setState({
      addSnippetError: 'Snippet konnte nicht angelegt werden',
      saving: false
    });
  }

  addSnippetSuccess(data) {
    const { snippets } = this.state;
    snippets.push(this.createLine(data));
    this.setState({
      inputModalIsOpen: false,
      saving: false,
      addSnippetError: false,
      snippets
    });
    this.gotoSnippet(data.ID, data.ID);
  }

  deleteSnippet(ID) {
    del(
      this.props.match.params.id,
      ID,
      this.successNext,
      () => this.setState({errorModalIsOpen: true}));
  }

  deleteError(error) {
    this.setState({
      error: error.response.data
    });
  }

  gotoSnippet(ID, index) {
    const { snippets } = this.state;
    const snippetById = this.getSnippetById(snippets, ID)
    const snippetData = this.setSnippetData(snippetById.snippet);
    const newIndex = snippetById.index;
    this.setState({
      snippetData,
      snippetIndex: newIndex,
      saveWarning: false
    });
    const { history } = this.props;
    history.push('/project/' + this.props.match.params.id + '/' + ID);
  }

  setSnippetData(actSnippet) {
    let newStatus = actSnippet.Status;
    if (newStatus === '') {
      newStatus = 'draft';
    }
    const snippetData = {
      Status: newStatus,
      URL: actSnippet.URL,
      title: this.setWarningLength({name: 'title', value: actSnippet.Title}),
      description: this.setWarningLength({name: 'description', value: actSnippet.Description}),
    };
    return snippetData;
  }

  sort(event, sortKey) {
      const { sorting, originalSnippets } = this.state;
      if(sorting.direction === 'desc') {
        sorting.direction = 'asc';
      }
      else{
        sorting.direction = 'desc';
      }
      sorting.key = sortKey;
      const snippets = this.createNewDataFromOriginal(originalSnippets, sortKey)
      const snippetById = this.getSnippetById(snippets, this.props.match.params.snippet);
      this.setState({
        snippets,
        sorting,
        snippetIndex: snippetById.index
      })
  }

  changePage(pageNo) {
    this.setState({pageNo});
  }

  specialcharacters(target) {
    if(typeof target !== 'undefined') {
      const { current } = this.refs[target.name];
      const currentInput = {
        target,
        selectionStart: current.selectionStart,
        selectionEnd: current.selectionEnd
      }
      this.setState({
        currentInput
      });
    }
  }

  resetTitleDescription(data, url) {
    let { snippetData } = this.state;

    const targetTitle = {
      name: 'title',
      value: data.title.text
    }
    const targetDescription = {
      name: 'description',
      value: data.description.text
    }

    snippetData.URL = url;
    snippetData.title = this.setWarningLength(targetTitle);
    snippetData.description = this.setWarningLength(targetDescription);
    this.setState({snippetData});

  }

  setGPT(target, length = 65, apikey = false) {
    let { snippetData, config } = this.state;
    const cookies = new Cookies();
    let language = cookies.get('language');
    if(!language) {
      language = 'de';
      this.setLanguage(null, 'de');
    }
    this.setState({loadingFromGpt: target});
    optimize(snippetData.URL, target, length, language, this.setGPTSuccess, this.setGPTError, config.gpt.apikey, config.gpt.userAgent, config.gpt.gptModel);
  }

  setGPTSuccess(data, target = 'title') {
    let { snippetData } = this.state;

    if(target === 'title') {
      snippetData.title = this.setWarningLength({name: 'title', value: data.seoTitle});
    }
    else {
      snippetData.description = this.setWarningLength({name: 'description', value: data.seoDescription});
    }
    this.setState({snippetData, loadingFromGpt: false});
  }

  setGPTError(error, response) {
    this.setState({loadingFromGpt: false, error: error.response.data.details});
  }

  render() {
    const styles = require('./Project.module.scss');
    const { maxLength, loading, service, device, snippetData, inputModalIsOpen, addSnippetError, saving, snippets, snippetIndex , sorting, progress, saveWarningIsOpen, error} = this.state;
    const { t } = this.props;
    const cookies = new Cookies();
    return (
      <div className={styles.project}>
        {!cookies.get('authtoken') && snippetData.URL === '' ?
          <VpContainer centered>
            <div className={`${styles.row}`}>
              <div className={styles['col-3'] + ' ' + styles.hiddenSmallerM}>
                &nbsp;
              </div>
              <div className={styles['col-6'] + ' ' + styles['col-m-12']}>
              <h1>{t('containers.project.loggedout.head')}</h1>
              <p>{t('containers.project.loggedout.text')}</p>
                <Urlinput t={t} setUrl={this.setUrl} />
              </div>
              <div className={styles['col-3'] + ' ' + styles.hiddenSmallerM}>
                &nbsp;
              </div>
            </div>
          </VpContainer>
          :
          <div>
            {(!loading || !cookies.get('authtoken')) ?
              <Container>
                <Edit
                  currentInput={this.state.currentInput}
                  snippets={snippets}
                  saving={saving}
                  inputModalToggle={this.inputModalToggle}
                  snippetData={snippetData}
                  service={service}
                  device={device}
                  snippetIndex={snippetIndex}
                  loading={loading}
                  setSnippet={this.setSnippet}
                  onLeave={this.onLeave}
                  progress={progress}
                  onChangeState={this.onChangeState}
                  saveAndNext={() => {this.gotoNextPrev(1)}}
                  saveAndPrev={() => {this.gotoNextPrev(-1)}}
                  saveAndSame={() => {this.editSnippet(0)}}
                  gotoSnippet={this.gotoSnippet}
                  setService={this.setService}
                  setDevice={this.setDevice}
                  maxLength={maxLength}
                  resetTitleDescription={this.resetTitleDescription}
                  setGPT={this.setGPT}
                  refs={this.refs}
                  t={t}
                  projectState={this.state}
                />
                <Snippets
                  sorting={sorting}
                  snippets={snippets}
                  changePage={this.changePage}
                  sort={this.sort}
                  gotoSnippet={this.gotoSnippet}
                  inputModalToggle={this.inputModalToggle}
                  refresh={this.load}
                  t={t}
                />
                {snippets.length < 1 ?
                  <div />
                  :
                  <div />
                }
              </Container>
              :
              <Container>
                <div className={styles.row}>
                  <div className={styles['col-12']}>
                    <Loader style={{'marginLeft': '10px', 'marginTop': '50px'}} />
                  </div>
                </div>
              </Container>
            }
          </div>
        }
        <SaveModal title={t('containers.project.loggedin.savemodal.title')} titleSaving={t('components.general.modals.titlesaving')} text={t('containers.project.loggedin.savemodal.text')} isOpen={saving}/>
        <ConfirmModal
          confirmAction={() => {
            this.setState({saveWarningIsOpen: false, saveWarning: false}, () => {
              this.gotoNextPrev(this.directionNextPrex);
            });
          }}
          closeAction={() => {this.setState({saveWarningIsOpen: false});}}
          title={t('containers.project.loggedin.confirmmodal.title')}
          titleSaving={t('components.general.modals.titlesaving')}
          text={t('containers.project.loggedin.confirmmodal.text')}
          confirmLabel={t('containers.project.loggedin.confirmmodal.confirmlabel')}
          canchelLabel={t('containers.project.loggedin.confirmmodal.canchellabel')}
          isOpen={saveWarningIsOpen}
        />
        <InputModal saveError={addSnippetError} saving={saving} titleSaving={t('components.general.modals.titlesaving')} title={t('containers.project.loggedin.inputmodal.title')} text={t('containers.project.loggedin.inputmodal.text')} label={t('containers.project.loggedin.inputmodal.label')} isOpen={inputModalIsOpen} closeAction={this.inputModalToggle} confirmAction={this.addSnippet} confirmLabel={t('containers.project.loggedin.inputmodal.confirmlabel')} canchelLabel={t('containers.project.loggedin.inputmodal.canchellabel')} />
        {apiError(error, this)}
      </div>
    );
  }
});
