import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Snippet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      widthInPx: {
        title: false,
        description: false
      }
    };
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  static propTypes = {
    service: PropTypes.string,
    device: PropTypes.string,
    showURL: PropTypes.bool,
    title: PropTypes.string,
    URL: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.object,
    changePxWidth: PropTypes.func
  };

  static defaultProps = {
    data: {},
    service: 'google',
    device: 'desktop',
    showURL: true,
    title: 'Online Marketing Jobs in Hamburg | Wingmen Online Marketing',
    URL: 'wngmn.de',
    description: 'Google Search Console-Seminar in Hamburg · Mehr über unsere Leistungen erfahren. kunden über uns. „Die Wingmen beraten uns bei wichtigen SEO Themen ...',
    changePxWidth: () => {}
  }

  componentDidMount() {
    const { changePxWidth } = this.props;
    const { widthInPx } =this.state;
    const { titleRef, descriptionRef } = this;
    widthInPx.title=titleRef.current.offsetWidth;
    widthInPx.description=descriptionRef.current.offsetWidth;
    this.setState({
      widthInPx
    }, changePxWidth(widthInPx));
  }

  componentDidUpdate(prevProps, prevState) {
    const { changePxWidth } = this.props;
    const { titleRef, descriptionRef } =this;
    const widthInPx = {
      title: titleRef.current.offsetWidth,
      description: descriptionRef.current.offsetWidth
    }
    if(prevState.widthInPx.title !== widthInPx.title || prevState.widthInPx.description !== widthInPx.description){
      this.setState({
        widthInPx
      }, changePxWidth(widthInPx));
    }
  }

  render() {
    const styles = require('./Snippet.module.scss');
    const { service, device, data, showUrl  } = this.props;
    return (
      <div className={styles.snippet + ' ' + styles[service] + ' ' + styles[device]}>
        {service === 'google' ?
          <div>
            {showUrl && <div className={`${styles.url}`}>{data.URL}</div>}
            <div className={`${styles.title}`}><span ref={this.titleRef}>{data.title.text}</span></div>
          </div>
          :
          <div>
            <div className={`${styles.title}`}><span ref={this.titleRef}>{data.title.text}</span></div>
            {showUrl && <div className={`${styles.url}`}>{data.URL}</div>}
          </div>
        }
        <div className={`${styles.description}`}><span ref={this.descriptionRef}>{data.description.text}</span></div>
      </div>
    );
  }
}
