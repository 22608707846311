import React from 'react';
import { Trans } from 'react-i18next';
import { ReactComponent as Logo } from '../../images/logo.svg';

const Footer = ({t, i18n}) => {
  const styles = require('./Footer.module.scss');
  return (
    <div className={`${styles.footer}`}>
      <div className={`${styles.footerInner}`}>
        <div className={`${styles.spalte} ${styles.spalte1}`}>
          <Logo className={styles.logo}/>
        </div>
        <div className={`${styles.spalte} ${styles.spalte2}`}>
          <div className={`${styles.inner}`}>
            <Trans i18nKey="components.footer.address" />
          </div>
        </div>
        <div className={`${styles.spalte} ${styles.spalte3}`}>
          <div className={`${styles.inner}`}>
            {t('components.footer.phone')}<br/>
            {t('components.footer.fax')}<br/>
            <a href={'https://' + t('components.footer.url')} title={t('components.footer.title')} rel="noopener noreferrer" target="_blank">{t('components.footer.url')}</a><br/>
          </div>
        </div>
        <div className={`${styles.spalte} ${styles.spalte4}`}>
          <div className={`${styles.inner}`}>
            <Trans i18nKey="components.footer.taxinfo" />
          </div>
        </div>
        <div className={`${styles.spalte} ${styles.spalte5}`}>
          <div className={`${styles.inner}`}>
            <nav>
              <a href="https://wngmn.de/impressum" rel="noopener noreferrer" target="_blank">{t('components.footer.imprint')}</a>
              <a href="https://wngmn.de/datenschutz" rel="noopener noreferrer" target="_blank">{t('components.footer.dataprotection')}</a>
              <a href="https://wngmn.de/agb" rel="noopener noreferrer" target="_blank">{t('components.footer.agb')}</a>
            </nav>
          </div>
        </div>
        <div className={`${styles.clearboth}`} />
      </div>
    </div>
  );
};

export default Footer;
