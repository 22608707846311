import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LengthCounter extends Component {
  static propTypes = {
    tileMaxLength: PropTypes.number,
    length: PropTypes.number,
    warningLength: PropTypes.string,
    width: PropTypes.number,
    label: PropTypes.string,
  };
  static get defaultProps() {
    return {
      tileMaxLength: 70,
      length: 10,
      warningLength: 'ok',
      width: 0,
      label: "Zeichen",
      of: "von"
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false,
    };
  }

  render() {
    const styles = require('./LengthCounter.module.scss');
    const { tileMaxLength, length, warningLength, width, label, of } = this.props;

    return (
      <div className={styles.lengthcounter + ' ' + styles[warningLength]}>{length} {of} {tileMaxLength} <span className={styles.hiddenSmallerM}>{label}</span> ({width}px)</div>
    );
  }
}
