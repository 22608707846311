import React from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Button from '../../Form/Button';
import Upload from '../../Form/Upload';

export default class UploadModal extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      text: PropTypes.string,
      isOpen: PropTypes.bool,
      closeAction: PropTypes.func,
      confirmAction: PropTypes.func,
      saving: PropTypes.bool,
      label: PropTypes.string,
      canchelLabel: PropTypes.string
    };

    static defaultProps= {
      title: 'Modal-Title',
      text: 'Modal-Text',
      isOpen: false,
      closeAction: () => {},
      confirmAction: () => {},
      saving: false,
      saveError: false,
      label: 'Bitte eingeben',
      canchelLabel: 'Uploas-Canchel-Label'
    };

    constructor(props) {
        super(props);
        this.state = {
          Name: '',
        };
        this.changeData = this.changeData.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentWillReceiveProps(props) {
      if(!props.saveError && !props.saving) {
        this.setState({Name: ''});
      }
    }

    // static getDerivedStateFromProps(props, state) {
    //   if(!props.saveError && !props.saving) {
    //     return {Name: ''};
    //   }
    // }

    changeData(target) {
      this.setState({
        Name: target.value
      });
    }

    confirm(event) {
      event.preventDefault();
      const { confirmAction } = this.props;
      confirmAction(this.state.Name);
    }

    render() {
        const styles = require('./modal.scss');
        const { title, text, isOpen, closeAction, canchelLabel, saving, onDrop } = this.props;
        return (
          <div className={styles.uploadmodal}>
            <Modal
              isOpen={isOpen}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeAction}
              // style={customStyles}
              contentLabel="Example Modal"
              className={'Modal'}
              overlayClassName={'Overlay'}
              ariaHideApp={false}
            >
              <form>
                {saving ?
                  <h2 className="modalTitle">{title} (speichere...)</h2>
                  :
                  <h2 className="modalTitle">{title}</h2>
                }
                <div className="modalBody">
                  <div className="modalForm">
                    <Upload onDrop={onDrop} text={text}/>
                  </div>
                </div>
                <div className="modalButtonBar">
                  <Button size="s" disabled={saving} onClick={closeAction}>{canchelLabel}</Button>
                </div>
              </form>
            </Modal>
          </div>
        );
    }
}
