import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Form/Button';
import Option from '../Form/Option';
import ButtonIcon from '../../components/Form/ButtonIcon';
import { ReactComponent as Arrowleft } from '../../images/arrows/arrow_left.svg';
import { ReactComponent as Arrowright } from '../../images/arrows/arrow.svg';

const styles = require('./SaveBarMobile.module.scss');

const buttonStyleLeft = {
  position: 'absolute',
  left: '0px',
  top: '0'
}
const buttonStyleRight = {
  position: 'absolute',
  right: '0px',
  top: '0'
}

const buttonStyleSave = {
  position: 'absolute',
  top:  '0',
  transform: 'translate(-50%, 0)',
  left: '50%'
}

const SaveBarMobile = ({ t, state, onChangeState, saveAndNext, saveAndPrev, saveAndSame, saving, progress }) => (
  <div className={styles.savebar}>
    <div className={styles.status}>
      <Option name="draft" onChange={onChangeState} active={state === 'draft' ? true : false} label={t('components.savebar.fields.draft')}/>
      <Option name="review" onChange={onChangeState} active={state === 'review' ? true : false} label={t('components.savebar.fields.review')}/>
      <Option disabled={saving} name="ready" onChange={onChangeState} active={state === 'ready' ? true : false} label={t('components.savebar.fields.ready')}/>
    </div>
    <div className={styles.inner}>
      <div className={styles.row}>
        <div className={styles['col-12']}>
          <ButtonIcon style={buttonStyleLeft} size="l" title={t('components.savebar.buttons.back')} onClick={saveAndPrev} disabled={saving}><Arrowleft /></ButtonIcon>
          <Button style={buttonStyleSave} disabled={saving} size="l" onClick={saveAndSame}>{t('components.savebar.buttons.save')}</Button>
          <ButtonIcon style={buttonStyleRight} size="l" title={t('components.savebar.buttons.next')} onClick={saveAndPrev} disabled={saving}><Arrowright /></ButtonIcon>
        </div>
      </div>
    </div>
    <div className={styles.progress}>
      <div className={styles.progressInner} style={{width: progress + '%'}}>{progress.toFixed(2).replace('.', ',')}%</div>
    </div>
  </div>
);

SaveBarMobile.propTypes = {
  // children: PropTypes.string.isRequired,
  state: PropTypes.string,
  onChangeState: PropTypes.func.isRequired,
  saveAndNext: PropTypes.func.isRequired,
  saveAndPrev: PropTypes.func.isRequired,
  saveAndSame: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  progress: PropTypes.number,
  t: PropTypes.func.isRequired
};

SaveBarMobile.defaultProps = {
  state: 'ready',
  saving: false,
  progress: 0,
};

export default SaveBarMobile;
