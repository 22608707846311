import React from 'react';
import { withRouter } from "react-router";
import VpContainer from '../../components/Container/VpContainer';
import SnippetSettingsCtaForm from '../../components/Htmlelements/SnippetSettingsCtaForm';
import Leftnav from '../../components/Htmlelements/Leftnav';
import Loader from '../../components/Htmlelements/Loader';
import {
  Link
} from "react-router-dom";
import Cookies from 'universal-cookie';
import {index} from '../../api/projects.js';
import apiError from '../../utils/apierrors.js';
const navpoints_de = require('../../json/nav.projectsettings.de.json');
const navpoints_en = require('../../json/nav.projectsettings.en.json');
const navpoints = {
  de: navpoints_de,
  en: navpoints_en,
}


export default withRouter(class ProjectSettingsCta extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: false,
        projectName: ''
      };
      this.error = this.error.bind(this);
      this.success = this.success.bind(this);
      this.load = this.load.bind(this);

      const cookies = new Cookies();
      this.language = cookies.get('language');
      if(!this.language) {
        this.language = 'de';
      }
    }

    static propTypes = {
    };

    componentDidMount() {
      this.load();
    }

    load() {
      index(this.success, this.error);
    }

    success(data) {
      this.setState({
        error: false,
        loading: false,
        projectName: this.getProjectName(data)
      });
    }

    getProjectName(data) {
      const { match } = this.props;
      let Name = '';
      data.forEach(project => {
        if(parseInt(project.ID) === parseInt(match.params.id)){
          Name = project.Name;
        }
      });
      return Name;
    }

    error(error) {
      this.setState({
        error: error.response.data,
        loading: false
      });
    }

    render() {
        const styles = require('./ProjectSettings.module.scss');
        const { loading, projectName, error } = this.state;
        const { t } = this.props;

        return (
          <div className={styles.settings}>
            {loading ?
              <VpContainer>
                <Loader style={{'marginLeft': '10px'}} />
              </VpContainer>
              :
              <VpContainer>
                <div className={`${styles.row}`}>
                  <div className={styles['col-12']}>
                    <h1 className={styles.breadcrump}><Link to={'/projects'}>{t('containers.projectsettings.head.first')}</Link><Link to={'/project/' + this.props.match.params.id}>{projectName}</Link>{t('containers.projectsettings.head.last')}</h1>
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                    <Leftnav navpoints={navpoints[this.language]} param={this.props.match.params.id} active={1} />
                  </div>
                  <div className={styles['col-9'] + ' ' + styles['col-m-12']}>
                    <SnippetSettingsCtaForm t={t} projectID={this.props.match.params.id} />
                  </div>
                </div>
              </VpContainer>
            }
            {apiError(error, this)}
          </div>
        );
    }
});
