import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import ButtonIconCircle from '../Form/ButtonIconCircle';
import Loader from '../Htmlelements/Loader';
import { ReactComponent as Edit } from '../../images/buttons/edit.svg';
import { ReactComponent as Hook } from '../../images/buttons/hook.svg';

import Textblock from './Textblocks/Textblock';
import {index, add, del} from '../../api/textblocks.js';
import apiError from '../../utils/apierrors.js';

export default withRouter(class Textblocks extends Component {

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
    t: PropTypes.func.isRequired
  };
  static defaultProps = {
    // module: 'Textbaustein',
    fieldName: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      text: '',
      loadingTextBlocks: true,
      saving: false,
      deleting: false,
      textBlocks: [],
      error: false
    };
    this.editmode = this.editmode.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadTextBlocks = this.loadTextBlocks.bind(this);
    this.loadTextBlocksSuccess = this.loadTextBlocksSuccess.bind(this);
    this.loadTextBlocksError = this.loadTextBlocksError.bind(this);
    this.saveSuccess = this.saveSuccess.bind(this);
    this.saveError = this.saveError.bind(this);
    this.deleteTextBlock = this.deleteTextBlock.bind(this);
    this.deleteSuccess = this.deleteSuccess.bind(this);
    this.deleteError = this.deleteError.bind(this);
  }

  componentDidMount() {
    this.loadTextBlocks();
  }

  loadTextBlocks() {
    index(this.props.match.params.id, this.loadTextBlocksSuccess, this.loadTextBlocksError);
  }

  loadTextBlocksSuccess(data) {
    this.setState({
      loadingTextBlocks: false,
      textBlocks: data
    });
  }

  loadTextBlocksError(error) {
    this.setState({
      loadingTextBlocks: false,
      error: error.response.data
    });
  }

  deleteTextBlock(id) {
    this.setState(
      {deleting: true},
      del(this.props.match.params.id, id, this.deleteSuccess, this.deleteError));
  }

  deleteSuccess(data) {
    this.setState({
      deleting: false,
      loadingTextBlocks: true
    });
    this.loadTextBlocks();
  }

  deleteError(error) {
    this.setState({
      deleting: false,
      error: error.response.data
    });
  }

  editmode() {
    const { edit } = this.state;
    this.setState({edit: !edit});
  }

  handleChange(event) {
    this.setState({
      text: event.target.value
    });
  }

  keyUpHandler(e) {
    const { text } = this.state;
    if(e.keyCode === 13 && text !== '') {
      add(this.props.match.params.id, text, this.saveSuccess, this.saveError)
      this.setState({saving: true, text: ''});
    }
  }

  saveSuccess(data) {
    this.setState({
      saving: false,
      loadingTextBlocks: true
    });
    this.loadTextBlocks();
  }

  saveError(error) {
    this.setState({
      saving: false,
      error: error.response.data
    });
  }

  render() {
    const styles = require('./Textblocks.module.scss');

    const { edit, loadingTextBlocks, textBlocks, saving, deleting, error } = this.state;
    const { t } = this.props;

    return (
      <div className={styles.textblocks}>
        <div className={styles.modules}>
          {textBlocks && textBlocks.map(textBlock => (
            <Textblock key={textBlock.Text} deleting={deleting} edit={edit} {...this.props} deleteItem={this.deleteTextBlock} textBlock={textBlock} />
          ))}
          {loadingTextBlocks && <Loader style={{'marginLeft': '10px', 'marginTop' : '8px', 'display': 'inline-block'}} text={t('components.textblocks.loading')} />}
        </div>
        {edit ?
          <div className={styles.active + ' ' + styles.toolbar}>
            {saving ?
              <Loader style={{'marginLeft': '10px', 'marginTop' : '10px'}} text={t('components.textblocks.saving')} /> :
              <div>
                <input autoFocus onKeyUp={this.keyUpHandler} onChange={this.handleChange} placeholder={t('components.textblocks.savehint')} type="text" className={styles.input} />
                <ButtonIconCircle onClick={this.editmode} style={{'position': 'absolute', 'right': '4px', 'bottom': '4px'}}><Hook /></ButtonIconCircle>
              </div>
            }
          </div>
          :
          <div className={styles.toolbar}>
            {!loadingTextBlocks &&<ButtonIconCircle onClick={this.editmode} style={{'position': 'absolute', 'right': '4px', 'bottom': '4px'}}><Edit /></ButtonIconCircle>}
          </div>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
