import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowFilled } from '../../images/arrows/arrow_filled.svg';
// import Button from '../Form/Button';
// import Input from '../Form/Input';
// import { withTranslation } from 'react-i18next';

export default class FeatureContainer extends Component {

  static propTypes = {
    headline: PropTypes.string,
    children: PropTypes.node.isRequired
  };
  static defaultProps = {
    headline: 'Headline',
  }

  constructor(props) {
    super(props);
    let open = true;
    if(window.innerWidth < 768) {
      open = false;
    }
    this.state = {
      open,
    };
    this.flap = this.flap.bind(this);
  }

  flap() {
    const { open } = this.state;
    this.setState({open: !open});
  }

  render() {
    const styles = require('./FeatureContainer.module.scss');

    // const { } = this.state;
    const { headline, children } = this.props;
    const { open } = this.state;

    return (
      <div className={open ? styles.featurecontainer + ' ' + styles.open : styles.featurecontainer + ' ' + styles.closed}>
        <h4>{headline}</h4>
        <button onClick={this.flap} className={styles.button}><ArrowFilled /></button>
        <div className={styles.inner}>
          {open && children}
        </div>
      </div>
    );
  }
}
