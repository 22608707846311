import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Error.module.scss');

const Error = ({ children }) => (
  <div className={styles.error}>{children}</div>
);

Error.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Error;
