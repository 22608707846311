/* eslint import/no-webpack-loader-syntax: off */
import React from 'react';
import PropTypes from 'prop-types';
import VpContainer from '../../components/Container/VpContainer';
const ReactMarkdown = require('react-markdown/with-html');

const styles = require('./Textpage.module.scss');

const Textpage = ({ md }) => (
  <VpContainer>
    <div className={`${styles.row}`}>
      <div className={styles['col-12'] + ' ' + styles.textpage}>
        <ReactMarkdown
          source={md}
          escapeHtml={false}
        />
      </div>
    </div>
  </VpContainer>
);

Textpage.propTypes = {
  md: PropTypes.string,
};
Textpage.defaultProps = {
  md: '# Hallo Welt!\nBitte schreibe hier deinen Text!',
};

export default Textpage;
