import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Container.module.scss');

const Container = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.inner}>
      {children}
    </div>
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
