import React from 'react';
import VpContainer from '../../components/Container/VpContainer';
import Login from '../../components/Htmlelements/Login';
import { withTranslation } from 'react-i18next';


const Loginform = ({ t, i18n }) => {
  // const { t, i18n } = useTranslation();
  const styles = require('./Login.module.scss');
  // const history = useHistory();
  // history.push("/projects");
  // i18n.changeLanguage('en-US');
  return (
    <div className={styles.home}>
      <VpContainer image centered>
        <div className={`${styles.row}`}>
          <div className={styles['col-6'] + ' ' + styles['col-l-12']}>
            <Login t={t} />
          </div>
        </div>
      </VpContainer>
    </div>
  );
};

export default withTranslation()(Loginform);
