import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Help from './help.svg';
import { ReactComponent as Loading } from '../../images/icons/loading.svg';

export default class Button extends Component {

  static propTypes = {
    children: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.any,
    size: PropTypes.string,
    labeltype: PropTypes.string,
    disabled: PropTypes.bool,
    breakpoint: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    title: PropTypes.string,
    name: PropTypes.string,
    additional: PropTypes.number,
    style: PropTypes.object,
    fullWidth: PropTypes.bool,
    focus: PropTypes.bool,
    className: PropTypes.object,
    loading: PropTypes.bool,
  };
  static defaultProps = {
    children: {},
    type: 'default',
    size: 's',
    labeltype: '',
    onClick: () => {
      alert('Clicked');
    },
    disabled: false,
    breakpoint: false,
    title: '',
    name: '',
    additional: 0,
    style: {},
    fullWidth: false,
    focus: false,
    className: {},
    loading: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false
    };
  }

  componentDidMount(prevProps, prevState) {
    const { focus } = this.props;
    if(focus) {
      this._button.focus();
    }
  }

  render() {
    const {additional, children, onClick, type, labeltype, size, fullWidth, disabled, breakpoint, title, name, style, className, loading} = this.props;
    const styles = require('./Button.module.scss');
    let isDisabled = disabled;
    if(loading) {
      isDisabled = true;
    }

    return (
      <button autoFocus={true} ref={c => (this._button = c)} style={style} name={'Button-' + name} title={title} className={fullWidth ? `${styles.fullWidth} ${styles.button} ${styles[breakpoint]} ${styles[type]} ${styles[size]} ${styles[labeltype]} ${className}` : `${styles.button} ${styles[breakpoint]} ${styles[type]} ${styles[size]} ${styles[labeltype]} ${className}`} disabled={isDisabled} onClick={onClick}>
        <div className={loading ? styles.buttonInner + ' ' + styles.noIcon + ' ' + styles.loading : styles.buttonInner + ' ' + styles.noIcon }>
          {loading && <Loading />}
          {children}
          {additional !== 0 && <span className={styles.additional}>{additional}</span>}
        </div>
      </button>
    );
  }
}
