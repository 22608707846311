import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import Dropzone from 'react-dropzone';

export default class Upload extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.array,
    validators: PropTypes.array,
    showValidation: PropTypes.bool,
    labeltop: PropTypes.bool,
    data: PropTypes.object,
    accept: PropTypes.string,
    hint: PropTypes.string,
    afterUpload: PropTypes.func,
    onDrop: PropTypes.func.isRequired
  };
  static get defaultProps() {
    return {
      name: 'foo',
      value: [],
      validators: [],
      showValidation: false,
      labeltop: false,
      data: {},
      accept: 'image/jpeg',
      text: 'Ziehe Dateien hier her oder klicke um Dateien auszuwählen.',
      afterUpload: () => {},
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false,
      files: this.props.value
    };
    
    this.validators = {
      email: value => {
        if (!validator.isEmail(value)) {
          return `${value} ist keine gültige E-Mail-Adresse.`;
        }
      },
      required: value => {
        if (!value.toString().trim().length) {
          return 'Dieses ist ein Pflichtfeld.';
        }
      },
      zip: value => {
        if (value.toString().trim().length < 4) {
          return 'Mind. 4-Stellig!';
        }
        if (!validator.isInt(value, { min: 1, max: 99999, allow_leading_zeroes: true })) {
          return '4- bis 5-Stellig, nur Zahlen!';
        }
      },
      phone: value => {
        if (!validator.isWhitelisted(value, '1234567890 + ()')) {
          return 'Keine gültige Telefonnummer!';
        }
      },
      password: value => {
        if (typeof value === 'undefined' || !value.toString().trim().length) {
          return 'Passwort und Wiederholung dürfen nicht leer sein!';
        }
        if (this.props.data.password !== this.props.data.passwordwdh) {
          return 'Passwort und Wiederholung müssen übereinstimmen.';
        }
      }
    };
  }

  render() {
    const styles = require('./Upload.module.scss');
    const { onDrop, text } = this.props;
    return (
      <div className={`${styles.row} ${styles.upload}`}>
        <Dropzone maxFiles={1} maxSize={1024 * 1000} multiple={false} onDrop={acceptedFiles => onDrop(acceptedFiles)}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{text}</p>
              </div>
            </section>
          )}
        </Dropzone>
        {this.state.error && <span className={`${styles.error}`}>{this.state.error}</span>}
      </div>
    );
  }
}
