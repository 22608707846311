import React from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Button from '../../Form/Button';

const styles = require('./modal.scss');

const ErrorModal = ({ title, text, isOpen, closeLabel, closeAction }) => {
  return (
    <div className={styles.ErrorModal}>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeAction}
        isOpen={isOpen}
        contentLabel="Example Modal"
        className={'Modal'}
        overlayClassName={'Overlay'}
      >
        <form>
          <h2 className="modalTitle">{title}</h2>
          <div className="modalBody">
            <div className="modalText">{text}</div>
          </div>
          <div className="modalButtonBar">
            <Button focus size="s" onClick={closeAction}>{closeLabel}</Button>
          </div>
        </form>
      </Modal>
    </div>
  )
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  closeLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  closeAction: PropTypes.func,
};

ErrorModal.defaultProps = {
  title: 'Fehler',
  closeLabel: 'Ok',
  text: 'Es ist ein Fehler aufgetreten!',
  isOpen: false,
  closeAction: () => {},
};

export default ErrorModal;
