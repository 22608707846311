import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import Headernav from './components/Htmlelements/Headernav';
import Footer from './components/Footer/Footer';
import Home from './containers/Home/Home';
import Login from './containers/Login/Login';
import Imprint from './containers/Imprint/Imprint';
import Agb from './containers/Agb/Agb';
import Dataprotection from './containers/Dataprotection/Dataprotection';
import Help from './containers/Help/Help';
import Registersuccess from './containers/Registersuccess/Registersuccess';
import DeleteUserSuccess from './containers/DeleteUserSuccess/DeleteUserSuccess';
import Endregistersuccess from './containers/Endregistersuccess/Endregistersuccess';
import Usersettings from './containers/Usersettings/Usersettings';
import Deleteuser from './containers/Usersettings/Deleteuser';
import ProjectSettings from './containers/Project/ProjectSettings';
import ProjectSettingsCta from './containers/Project/ProjectSettingsCta';
import ProjectSettingsEmojis from './containers/Project/ProjectSettingsEmojis';
import ProjectSettingsCustomEmojis from './containers/Project/ProjectSettingsCustomEmojis';
import ProjectSettingsGpt from './containers/Project/ProjectSettingsGpt';
import Activate from './containers/Activate/Activate';
import Project from './containers/Project/Project';
import Projects from './containers/Projects/Projects';
import Snippets from './containers/Snippets/Snippets';
import './App.scss';

function App() {
  const { t } = useTranslation();
  return (
    <Router>
      <div className="App">
        <div className='holder'>
          <div className="hiddenBiggerM">
            <Switch>
              <Route path="/project/:id?" render={(props) => <Headernav t={t} active="projects" /> } />
              <Route path="/projects"><Headernav t={t} active={'projects'} /></Route>
              <Route path="/usersettings/password"><Headernav t={t} active={'usersettings'} /></Route>
              <Route path="/usersettings/deleteuser"><Headernav t={t} active={'usersettings'} /></Route>
              <Route path="/settings/general"><Headernav t={t} active={'settings'} /></Route>
              <Route path="/help"><Headernav t={t} active={'help'} /></Route>
              <Route><Headernav t={t}  /></Route>
            </Switch>
          </div>
          <Switch>
            <Route path="/project/:id?" render={(props) => <Header t={t} {...props} /> } />
            <Route path="/projects"><Header t={t} active={'projects'} /></Route>
            <Route path="/usersettings/password"><Header t={t} active={'usersettings'} /></Route>
            <Route path="/usersettings/deleteuser"><Header t={t} active={'usersettings'} /></Route>
            <Route path="/settings/general"><Header t={t} active={'settings'} /></Route>
            <Route path="/help"><Header t={t} active={'help'} /></Route>
            <Route><Header t={t}  /></Route>
          </Switch>
          <Switch>
            <Route exact path="/">
              <Home t={t} />
            </Route>
            <Route exact path="/login">
              <Login t={t} />
            </Route>
            <Route exact path="/project/:id?/:snippet?" render={(props) => <Project t={t} {...props} /> } />
            <Route exact path="/projects">
              <Projects t={t} />
            </Route>
            <Route exact path="/snippets/:id?" render={(props) => <Snippets t={t} {...props} /> } />
            <Route path="/imprint">
              <Imprint />
            </Route>
            <Route path="/agb">
              <Agb />
            </Route>
            <Route path="/dataprotection">
              <Dataprotection />
            </Route>
            <Route path="/help">
              <Help />
            </Route>
            <Route path="/registersuccess">
              <Registersuccess />
            </Route>
            <Route path="/endregistersuccess">
              <Endregistersuccess t={t} />
            </Route>
            <Route path="/usersettings/password">
              <Usersettings t={t} />
            </Route>
            <Route path="/usersettings/deleteuser">
              <Deleteuser t={t} />
            </Route>
            <Route path="/projectsettings/snippets/length/:id">
              <ProjectSettings t={t} />
            </Route>
            <Route path="/projectsettings/snippets/cta/:id">
              <ProjectSettingsCta t={t} />
            </Route>
            <Route path="/projectsettings/snippets/emojis/:id">
              <ProjectSettingsEmojis t={t} />
            </Route>
            <Route path="/projectsettings/snippets/customemojis/:id">
              <ProjectSettingsCustomEmojis t={t} />
            </Route>
            <Route path="/projectsettings/snippets/gpt/:id">
              <ProjectSettingsGpt t={t} />
            </Route>
            <Route path="/usersettings/deleteusersuccess">
              <DeleteUserSuccess t={t} />
            </Route>
            <Route exact path="/activate/:activationcode?" render={(props) => <Activate t={t} {...props} /> } />
          </Switch>
        </div>
        <Footer t={t} />
      </div>
    </Router>
  );
}

export default App;
