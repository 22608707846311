import React from 'react';
import PropTypes from 'prop-types';
import Loader from './Loader';
import Cookies from 'universal-cookie';
import apiError from '../../utils/apierrors.js';

import { withRouter } from "react-router";
import {
  Link
} from "react-router-dom";

import { ReactComponent as ArrowFilled } from '../../images/arrows/arrow_filled.svg';
import { ReactComponent as Arrow } from '../../images/arrows/arrow.svg';

import {index} from '../../api/projects.js';

export default withRouter(class ProjectSelector extends React.Component {

  static propTypes = {
    headline: PropTypes.string,
    active: PropTypes.bool,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    label: PropTypes.string,
  };
  static defaultProps = {
    headline: 'Headline',
    label: 'Projectselector-Label',
    active: false
  }

  componentDidMount() {
    this.load();
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: false,
      loading: true,
      projects: [],
      actual: ''
    };
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: false,
      error: false,
      loading: true,
      projects: [],
      actual: ''
    });
    this.load();
  }

  toggle() {
    this.setState({open: !this.state.open});
  }

  select(ID) {
    const { history } = this.props;
    history.push('/project/' + ID);
    this.setState({open: false});
  }

  load() {
    const cookies = new Cookies();
    if(cookies.get('authtoken')){
        index(this.success, this.error);
    }
  }

  success(data) {
    const { match } = this.props;
    let actual = '';
    data.forEach(val => {
      if(String(val.ID) === String(match.params.id)) {
        actual = val;
      }
      // newData.push(this.createLine(val));
    });
    this.setState({
      error: false,
      loading: false,
      projects: data,
      actual
    });
  }

  error(error) {
    let returnError = false;
    if(error.response) {
      returnError = error.response.data;
    }
    this.setState({
      error: returnError,
      loading: false
    });
  }

  render() {
    const styles = require('./ProjectSelector.module.scss');

    const { open, loading, actual, projects, error } = this.state;
    const { match, active, label } = this.props;
    const cookies = new Cookies();
    if(!cookies.get('authtoken')){
        return (<div/>);
    }
    return (
      <div className={open ? styles.projectselector + ' ' + styles.open : styles.projectselector}>
        <Link className={active ? styles.active : styles.inactive} to="/projects">{label}</Link>
        {!active &&
          <div className={styles.activeTest}>
            {typeof match.params.id !== 'undefined' && !loading &&
              <ArrowFilled className={styles.arrowFilled} />
            }
            {typeof match.params.id !== 'undefined' ?
              <div className={styles.dropdown}>
                {loading ?
                  <Loader style={{'marginLeft': '10px'}}/>
                  :
                  <div>
                    <button onClick={this.toggle} className={styles.opener}>{actual.Name}<Arrow className={styles.arrow}/></button>
                    <div className={styles.flyout}>
                      <ul>
                        {projects.map(line => (
                          <li key={line.ID}><button disabled={actual.ID === line.ID ? true : false} onClick={() => this.select(line.ID)} className={styles.button}>{line.Name}</button></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                }
              </div> :
              <div className={styles.dropdown}>
                {loading ?
                  <Loader style={{'marginLeft': '10px'}}/>
                  :
                  <div>
                    <button onClick={this.toggle} className={styles.opener + ' ' + styles.empty}>&nbsp;<Arrow className={styles.arrow}/></button>
                    <div className={styles.flyout}>
                      <ul>
                        {projects.map(line => (
                          <li key={line.ID}>{actual.Name}<button disabled={actual.ID === line.ID ? true : false} onClick={() => this.select(line.ID)} className={styles.button}>{line.Name}</button></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
