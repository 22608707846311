import React, { Component } from 'react';
import PropTypes from "prop-types";
import Button from '../Form/Button';
import Input from '../Form/Input';

export default class Urlinput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      url: ''
    };
    this.changeData = this.changeData.bind(this);
    this.urlinput = this.urlinput.bind(this);

  }

  static propTypes = {
    setUrl: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  urlinput(event) {
    const { url } = this.state;
    const { setUrl } = this.props;
    event.preventDefault();
    setUrl(url);
  }

  changeData(target) {
    this.setState({url: target.value});
  }

  render() {
    const styles = require('./Urlinput.module.scss');

    const { url } = this.state;
    const { t } = this.props;

    return (
      <div className={styles.urlinput}>
        <form onSubmit={this.urlinput} className={styles.urlinputform}>
          <Input label={t('components.urlinput.fields.url')} size="xxl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="url" value={url}/>
          <Button style={{'float': 'right'}} fullWidth disabled={url === '' ? true : false} onClick={this.urlinput} size="l">{t('components.urlinput.buttons.load')}</Button>
        </form>
      </div>
    );
  }
};
