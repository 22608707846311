const edit = require('./components/edit.json');
const endregister = require('./components/endregister.json');
const footer = require('./components/footer.json');
const general = require('./components/general.json');
const header = require('./components/header.json');
const headernav = require('./components/headernav.json');
const login = require('./components/login.json');
const projectselector = require('./components/projectselector.json');
const register = require('./components/register.json');
const savebar = require('./components/savebar.json');
const snippetcontainer = require('./components/snippetcontainer.json');
const snippets = require('./components/snippets.json');
const snippetsettingsform = require('./components/snippetsettingsform.json');
const snippetsettingsctaform = require('./components/snippetsettingsctaform.json');
const snippetsettingsemojisform = require('./components/snippetsettingsemojisform.json');
const snippetsettingscustomemojisform = require('./components/snippetsettingscustomemojisform.json');
const snippetsettingsgptform = require('./components/snippetsettingsgptform.json');
const specialcharacters = require('./components/specialcharacters.json');
const textblocks = require('./components/textblocks.json');
const url = require('./components/url.json');
const urlinput = require('./components/urlinput.json');
const usersettingsform = require('./components/usersettingsform.json');
const copybar = require('./components/copybar.json');

const containers = {
  edit,
  endregister,
  footer,
  general,
  header,
  headernav,
  login,
  projectselector,
  register,
  savebar,
  snippetcontainer,
  snippets,
  snippetsettingsform,
  snippetsettingsctaform,
  snippetsettingsemojisform,
  snippetsettingscustomemojisform,
  snippetsettingsgptform,
  specialcharacters,
  textblocks,
  url,
  urlinput,
  usersettingsform,
  copybar
}

export default containers;
