import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import Loader from './Loader';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { withRouter } from "react-router";
// import { withTranslation } from 'react-i18next';
import { update, load } from '../../api/config.js';
import apiError from '../../utils/apierrors.js';
import { ReactComponent as Smiley2 } from '../../images/smileys/smiley_2.svg';
import { ReactComponent as Smiley3 } from '../../images/smileys/smiley_3.svg';
import { ReactComponent as Smiley4 } from '../../images/smileys/smiley_4.svg'
export default withRouter(class SnippetSettingsEmojisForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        error: 'Bitte Eingaben vervollständigen',
      },
      error: false,
      saving: false,
      loading: true,
      defaultSettings: {}
    };
    this.services = ['google', 'bing', 'yandex'];
    this.changeData = this.changeData.bind(this);
    this.update = this.update.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.updateError = this.updateError.bind(this);
    this.load = this.load.bind(this);
    this.loadSuccess = this.loadSuccess.bind(this);
    this.loadError = this.loadError.bind(this);
    this.errorModalToggle = this.errorModalToggle.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    projectID: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  load(){
    const { projectID } = this.props;
    load(projectID, this.loadError, this.loadSuccess);
  }

  componentDidMount(){
    this.load();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  loadSuccess(data){
    const { emojis } = data.snippets;
    const newFormData = {
      emojis: emojis
    };
    this.setState({
      formData: newFormData,
      loading: false,
      defaultSettings: data
    });
  }

  loadError(error){
    this.setState({
      loading: false,
      error: error.response.data
    });
  }

  update(event) {
    const { formData, defaultSettings } = this.state;
    const { projectID } = this.props;
    defaultSettings.snippets.emojis = formData.emojis;
    this.setState({saving: true});
    update(projectID, defaultSettings, this.updateError, this.updateSuccess);
    event.preventDefault();
  }

  updateSuccess() {
    this.setState({
      saving: false,
      error: false
    });
  }

  updateError(error) {
    this.setState({
      error: error.response.data,
      saving: false
    });
  }

  errorModalToggle(event) {
    event.preventDefault();
    const { errorModalIsOpen } = this.state;
    this.setState({
      errorModalIsOpen: !errorModalIsOpen,
      // error: false
    });
  }

  changeData(values) {
    const newData = Object.assign({}, this.state.formData);
    newData.emojis = values;
    this.setState({
      formData: newData,
    });
  }

  render() {
    const styles = require('./SnippetSettingsForm.module.scss');

    const { formData, saving, loading, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();

    let railStyle = {};
    if(!loading) {
      railStyle = {
        'background': 'linear-gradient(90deg, #009900 0%, #FFAA00 ' + String(formData.emojis[0]) + '0%, #FFAA00 ' + String(formData.emojis[1]) + '0%, #cc0000 100%)'
      }
    }

    return (
      <div className={styles.generalsettings}>
        {!cookies.get('authtoken') ?
          <div>Du bist nicht eingeloggt!</div>
          :
          <form onSubmit={this.update} className={styles.generalsettingsform}>
            {loading ?
              <Loader style={{'marginLeft': '10px'}}/>
              :
              <div>
                <h2 className={styles.formhead}>{t('components.snippetsettingsemojisform.head')}</h2>
                <p className={styles.formhead}>{t('components.snippetsettingsemojisform.text')}</p>
                <div className={`${styles.row}`}>
                  <div className={styles['col-12']}>
                    <Range
                      min={0}
                      max={10}
                      defaultValue={[formData.emojis[0],formData.emojis[1]]}
                      onAfterChange={values => this.changeData(values)}
                      railStyle={railStyle}
                      trackStyle={[{ backgroundColor: 'transparent' }]}
                      dots
                      className={styles.slider}
                      pushable
                      ariaLabelGroupForHandles="Test"
                      ariaLabelledByGroupForHandles="Testtest"
                      marks={{
                        '0': '0',
                        '1': '1',
                        '2': '2',
                        '3': '3',
                        '4': '4',
                        '5': '5',
                        '6': '6',
                        '7': '7',
                        '8': '8',
                        '9': '9',
                        '10': '10',
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.row}`}>
                  <div className={styles['col-12']}>
                    <ul className={styles.sliderinfo}>
                      <li className={styles.okInfo}><Smiley4 />{t('components.snippetsettingsemojisform.info.ok.1')} {formData.emojis[0]} {t('components.snippetsettingsemojisform.info.ok.2')}</li>
                      <li className={styles.warningInfo}><Smiley3 />{t('components.snippetsettingsemojisform.info.warning.1')} {formData.emojis[0]} {t('components.snippetsettingsemojisform.info.warning.2')} {formData.emojis[1]} {t('components.snippetsettingsemojisform.info.warning.3')}</li>
                      <li className={styles.errorInfo}><Smiley2 />{t('components.snippetsettingsemojisform.info.error.1')} {formData.emojis[1]} {t('components.snippetsettingsemojisform.info.error.2')}</li>
                    </ul>
                  </div>
                </div>
                <Button style={{'float': 'right'}} disabled={formData.error ? true : false} onClick={this.update} size="m">{t('components.snippetsettingsemojisform.buttons.save')}</Button>
                {formData.error && <span className={styles.error}>{formData.error}</span>}
              </div>
            }
          </form>
        }
        {saving && <SaveModal title="Speichere" titleSaving={t('components.general.modals.titlesaving')} isOpen={saving}/>}
        {apiError(error, this)}
      </div>
    );
  }
});
