import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./VpContainer.module.scss');

const VpContainer = ({ children, image, centered }) => (
  <div className={image ? styles.vpcontainer + ' ' + styles.image : styles.vpcontainer}>
    <div className={centered ? styles.inner + ' ' + styles.centered : styles.inner}>
      {children}
    </div>
  </div>
);

VpContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  image: PropTypes.bool,
  centered: PropTypes.bool,
};

VpContainer.defaultProps = {
  image: false,
  centered: false,
};

export default VpContainer;
