import React, { Component } from 'react';
import PropTypes from 'prop-types';







export default class ButtonIcon extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
    onClick: PropTypes.func,
    size: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    confirm: PropTypes.bool,
    inverted: PropTypes.bool,
  };
  static defaultProps = {
    children: {},
    size: 's',
    icon: '',
    onClick: () => {
      alert('Clicked');
    },
    disabled: false,
    title: '',
    name: '',
    style: {},
    confirm: false,
    inverted: false
  };

  constructor(props) {
    super(props);
    this.state = {
      asking: false
    };
    this.action = this.action.bind(this);
  }

  action() {
    const { onClick, confirm } = this.props;
    const { asking } = this.state;
    if(asking) {
      this.setState({asking: false})
      onClick();
    } else {
      if(confirm) {
        this.setState({asking: true})
      } else {
        onClick();
      }
    }
  }

  render() {
    const { children, disabled, size, title, name, style, inverted } = this.props;
    const { asking } = this.state;
    const styles = require('./ButtonIcon.module.scss');
    return (
      <>
        <div className={asking ? styles.asking : styles.notasking} onMouseLeave={() => this.setState({asking: false})}>{asking &&
            <span className={styles.askInner}>{title}?</span>
        }<button style={style} name={'Button-' + name} title={title} className={inverted ? `${styles.buttonicon} ${styles[size]} ${styles.inverted}` : `${styles.buttonicon} ${styles[size]}`} disabled={disabled} onClick={this.action}>
            {children}
          </button>
        </div>
      </>
    );
  }
}
