import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from "react-router";

import { ReactComponent as Arrow } from '../../images/arrows/arrow.svg';

export default withRouter(class SimpleSelect extends React.Component {

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selected: PropTypes.number,
    values: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired
    // children: PropTypes.node.isRequired
  };
  static defaultProps = {
    selected: 0
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      error: false,
      loading: true,
      projects: [],
      actual: ''
    };
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: false,
      error: false,
      loading: true,
      projects: [],
      actual: ''
    });
  }

  toggle() {
    this.setState({open: !this.state.open});
  }

  select(index) {
    const { onClick } = this.props;
    onClick(index);
  }

  render() {
    const styles = require('./SimpleSelect.module.scss');

    const { open } = this.state;
    const { values, selected } = this.props;
    // const cookies = new Cookies();
    // if(!cookies.get('authtoken')){
    //     return (<div/>);
    // }
    return (
      <div className={open ? styles.simpleselect + ' ' + styles.open : styles.simpleselect}>
          <div className={styles.dropdown}>
            <div>
              <button onClick={this.toggle} className={styles.opener}>{values[selected]}<Arrow className={styles.arrow}/></button>
              <div className={styles.flyout}>
                <ul>
                  {values.map((value, i) => (
                    <li key={value}><button disabled={i === selected ? true : false} onClick={() => this.select(i)} className={styles.button}>{value}</button></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
      </div>
    );
  }
});
