import React from 'react';
import Modal from 'react-modal'
import PropTypes from 'prop-types';
import Button from '../../Form/Button';
import Input from '../../Form/Input';

export default class InputModal extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      titleSaving: PropTypes.string,
      text: PropTypes.string,
      isOpen: PropTypes.bool,
      closeAction: PropTypes.func,
      confirmAction: PropTypes.func,
      saving: PropTypes.bool,
      saveError: PropTypes.any,
      label: PropTypes.string,
      confirmLabel: PropTypes.string,
      canchelLabel: PropTypes.string,
    };

    static defaultProps= {
      title: 'Modal-Title',
      titleSaving: 'Modal-Title-Saving',
      text: 'Modal-Text',
      isOpen: false,
      closeAction: () => {},
      confirmAction: () => {},
      saving: false,
      saveError: false,
      label: 'Modal-Label',
      confirmLabel: 'Modal-Ok',
      canchelLabel: 'Modal-Canchel'
    };

    constructor(props) {
        super(props);
        this.state = {
          Name: '',
        };
        this.changeData = this.changeData.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentWillReceiveProps(props) {
      if(!props.saveError && !props.saving) {
        this.setState({Name: ''});
      }
    }

    changeData(target) {
      this.setState({
        Name: target.value
      });
    }

    confirm(event) {
      event.preventDefault();
      const { confirmAction } = this.props;
      confirmAction(this.state.Name);
    }

    render() {
        const styles = require('./modal.scss');
        const { title, titleSaving, text, isOpen, closeAction, saving, saveError, label, confirmLabel, canchelLabel } = this.props;
        const { Name } = this.state;

        return (
          <div className={styles.inputmodal}>
            <Modal
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick
              onRequestClose={closeAction}
              isOpen={isOpen}
              // onRequestClose={closeModal}
              // style={customStyles}
              contentLabel="Example Modal"
              className={'Modal'}
              overlayClassName={'Overlay'}
              ariaHideApp={false}
            >
              <form>
                {saving ?
                  <h2 className="modalTitle">{title} ({titleSaving})</h2>
                  :
                  <h2 className="modalTitle">{title}</h2>
                }
                <form className="modalBody" onSubmit={this.confirm}>
                  <div className="modalText">{text}</div>
                  <div className="modalForm">
                    <Input error={saveError} autoFocus fullWidth disabled={saving} label={label} size="s" holderstyles={styles.holderstyles} onChange={this.changeData} name="email" value={Name}/>
                  </div>
                </form>
                <div className="modalButtonBar">
                  <Button size="s" disabled={saving} onClick={closeAction}>{canchelLabel}</Button><Button focus={false} size="s" disabled={saving} onClick={this.confirm}>{confirmLabel}</Button>
                </div>
              </form>
            </Modal>
          </div>
        );
    }
}
