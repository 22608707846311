import React from 'react';
import PropTypes from 'prop-types';
// import Help from './help.svg';

const ButtonIconCircle = ({
  additional, children, onClick, type, labeltype, size, disabled, breakpoint, title, name, style
}) => {
  const styles = require('./ButtonIconCircle.module.scss');
  return (
    <button style={style} title={title} className={styles.buttoniconcircle} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

ButtonIconCircle.propTypes = {
  children: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.any,
  size: PropTypes.string,
  labeltype: PropTypes.string,
  disabled: PropTypes.bool,
  breakpoint: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.string,
  name: PropTypes.string,
  additional: PropTypes.number,
  style: PropTypes.object
};

ButtonIconCircle.defaultProps = {
  children: {},
  type: 'default',
  size: 's',
  labeltype: '',
  onClick: () => {
    alert('Clicked');
  },
  disabled: false,
  breakpoint: false,
  title: '',
  name: '',
  additional: 0,
  style: {}
};

export default ButtonIconCircle;
