import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Tab from './Tab';
import Snippet from './Snippet';
import Loader from '../../components/Htmlelements/Loader';
import Error from '../../components/Htmlelements/Error';
import Url from '../../components/Htmlelements/Url';
import Input from '../Form/Input';
import Cookies from 'universal-cookie';
import ButtonIcon from '../Form/ButtonIcon';

import { ReactComponent as Google } from '../../images/tabs/google.svg';
import { ReactComponent as Bing } from '../../images/tabs/bing.svg';
import { ReactComponent as Yandex } from '../../images/tabs/yandex.svg';

import { ReactComponent as OK } from '../../images/buttons/check.svg';
import { ReactComponent as Reset } from '../../images/buttons/reset.svg';

import { ReactComponent as Desktop } from '../../images/tabs/desktop.svg';
import { ReactComponent as Mobile } from '../../images/tabs/mobile.svg';

import {view} from '../../api/actualsnippet.js';

export default withRouter(class SnippetContainer extends Component {

  constructor(props) {
    super(props);
    const { url } = this.props;
    let loading = false;
    if(url) {
      loading = true;
    }
    this.state = {
      loading,
      error: false,
      data: this.props.data,
      stateurl: url
    };
    this.load = this.load.bind(this);
    this.loadSuccess = this.loadSuccess.bind(this);
    this.loadError = this.loadError.bind(this);
    this.changeUrl = this.changeUrl.bind(this);
    this.setUrl = this.setUrl.bind(this);

  }

  static propTypes = {
    tabbed: PropTypes.bool,
    service: PropTypes.string,
    device: PropTypes.string,
    onClickServices: PropTypes.func,
    onClickDevice: PropTypes.func,
    data: PropTypes.object.isRequired,
    onChangeUrlState: PropTypes.func,
    showUrl: PropTypes.bool,
    url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    resetTitleDescription: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    match: PropTypes.object.isRequired,
    changePxWidth: PropTypes.func,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    tabbed: false,
    onChangeUrlState: () => {},
    service: 'google',
    device: 'desktop',
    onClickServices: () => {alert('Clicked');},
    onClickDevice: () => {alert('Clicked');},
    showUrl: true,
    data: {
      URL: 'abc',
      title: {
        percentWarning: 1,
        warningLength: 'ok',
        text: ''
      },
      description: {
        percentWarning: 1,
        warningLength: 'ok',
        text: ''
      }
    },
    url: false,
    resetTitleDescription: false,
    changePxWidth: () => {}
  }

  componentDidMount() {
    const { url } = this.props;
    if(url) {
      this.load(url);
    }
  }

  componentDidUpdate(prevProps) {
    const { url, data } = this.props;
    if(url && url !== prevProps.url) {
      this.load(url);
    }
    else{
      if(data !== prevProps.data){
        this.setState({data: this.props.data, loading: false, error: false});
      }
    }
  }

  changeUrl(target) {
    this.setState({
      stateurl: target.value
    });
  }

  setUrl(target) {
    this.load(this.state.stateurl);
  }

  load(url) {
    const { match } = this.props
    this.setState({loading: true, error: false});
    view(url, match.params.id, this.loadSuccess, this.loadError);
  }

  loadSuccess(data) {
    const { showUrl, resetTitleDescription } = this.props;
    const { stateurl } = this.state;

    const actualSnippet = {
      showUrl,
      URL: this.state.stateurl,
      title: {
        percentWarning: 1,
        warningLength: 'ok',
        text: data.Title
      },
      description: {
        percentWarning: 1,
        warningLength: 'ok',
        text: data.Description
      },
      Status: 'draft',
    };

    // Setzt links auf den geladenen Title etc wenn nicht eingeloggt
    const cookies = new Cookies();
    if(!cookies.get('authtoken')) {
      resetTitleDescription(actualSnippet, stateurl);
    }
    this.setState({data: actualSnippet, loading: false});
  }

  loadError() {
    this.setState({error: true, loading: false});
  }

  render() {
    const styles = require('./SnippetContainer.module.scss');

    const cookies = new Cookies();

    const { t, tabbed, service, device, onClickServices, onClickDevice, url, resetTitleDescription } = this.props;
    const { data, loading, error, stateurl } = this.state;


    return (
      <div className={styles.snippetcontainer}>
        {error ?
          <div className={styles.inner}><Error>{t('components.snippetcontainer.error')}</Error></div>
          :
          <div className={styles.inner}>
            {loading ?
              <Loader /> : <Snippet {...this.props} data={data} />
            }
          </div>
        }
        {url && !loading &&
          <div>
            {cookies.get('authtoken') ?
              <div className={styles.url}><Url {...this.props} icon t={t} /></div>
              :
              <form className={styles.urlInput} onSubmit={this.setUrl}>
                <Input label="URL" fullWidth size="xs" holderstyles={styles.holderstyles} onChange={this.changeUrl} name={url} value={stateurl}/>
                <ButtonIcon size="xs" title="Ok" onClick={this.setUrl}><OK /></ButtonIcon>
              </form>
            }
            <ButtonIcon inverted style={{marginTop: '10px'}} size="xs" title={t('components.snippetcontainer.buttons.reset')} onClick={() => resetTitleDescription(data, url)}><Reset /></ButtonIcon>
          </div>
        }
        {tabbed &&
          <div>
            <div className={styles.tabs}>
              <ul>
                <Tab active={service === 'google'} onClick={() => {onClickServices('google')}}>
                  <Google />
                </Tab>
                <Tab active={service === 'bing'} onClick={() => {onClickServices('bing')}}>
                  <Bing />
                </Tab>
                <Tab active={service === 'yandex'} onClick={() => {onClickServices('yandex')}}>
                  <Yandex />
                </Tab>
              </ul>
            </div>
            <div className={styles.tabs}>
              <ul>
                <Tab active={device === 'desktop'} onClick={() => {onClickDevice('desktop')}} corporate>
                  <Desktop />
                </Tab>
                <Tab active={device === 'mobile'} onClick={() => {onClickDevice('mobile')}} corporate>
                  <Mobile/>
                </Tab>
              </ul>
            </div>
          </div>
        }
      </div>
    );
  }
});
