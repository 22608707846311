import React from 'react';
import PropTypes from 'prop-types';
// import Help from './help.svg';
import { ReactComponent as Arrow } from '../../images/arrows/arrow.svg';

const ArrowButton = ({
  onClick, type, direction, disabled, name,
}) => {
  const styles = require('./ArrowButton.module.scss');
  return (
    <button name={'Button-' + name} className={`${styles.arrowbutton} ${styles[type]}`} disabled={disabled} onClick={onClick}>
      &nbsp;
      <Arrow className={styles[direction] + ' ' + styles[type]} />
      {type === 'double' &&
        <Arrow className={styles[direction] + ' ' + styles[type]} />
      }
    </button>
  );
};

ArrowButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

ArrowButton.defaultProps = {
  type: 'single',
  direction: 'r',
  onClick: () => {
    alert('Clicked');
  },
  disabled: false,
  name: '',
};

export default ArrowButton;
