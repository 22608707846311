const axios = require('axios');

const add = (email, password, errorCallback, successCallback) => {
  axios.post('/v1/user/', {
    Email: email,
    Password: password
  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default add;
