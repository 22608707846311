import React from 'react';
import {
  Link
} from "react-router-dom";
import PropTypes from 'prop-types';



const styles = require('./IconLink.module.scss');

const IconLink = ({ to, icon, children, active, breakSmall, style }) => {
  if(breakSmall) {
    return(
      <Link style={style} to={to} className={active ? styles.active + ' ' + styles.iconlink + ' ' + styles.breakSmall : styles.inactive + ' ' + styles.iconlink + ' ' + styles.breakSmall}>{React.createElement(icon)}{children}</Link>
    )
  }
  return(
    <Link style={style} to={to} className={active ? styles.active + ' ' + styles.iconlink : styles.inactive + ' ' + styles.iconlink}>{React.createElement(icon)}{children}</Link>
  )
};

IconLink.propTypes = {
  // style: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.array.isRequired]),
  to: PropTypes.string,
  icon: PropTypes.object.isRequired,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  breakSmall: PropTypes.bool
};

IconLink.defaultProps = {
  to: '/',
  active: false,
  breakSmall: false,
  style: {}
};

export default IconLink;
