import React, { Component } from 'react';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
import Button from '../Form/Button';
import Loader from './Loader';
import Input from '../Form/Input';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import { withRouter } from "react-router";
// import { withTranslation } from 'react-i18next';
import { update, load } from '../../api/config.js';
import apiError from '../../utils/apierrors.js';
export default withRouter(class SnippetSettingsCtaForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        error: 'Bitte Eingaben vervollständigen',
      },
      error: false,
      saving: false,
      loading: true,
      defaultSettings: {}
    };
    this.services = ['google', 'bing', 'yandex'];
    this.changeData = this.changeData.bind(this);
    this.update = this.update.bind(this);
    this.updateSuccess = this.updateSuccess.bind(this);
    this.updateError = this.updateError.bind(this);
    this.load = this.load.bind(this);
    this.loadSuccess = this.loadSuccess.bind(this);
    this.loadError = this.loadError.bind(this);
    this.validate = this.validate.bind(this);
    this.errorModalToggle = this.errorModalToggle.bind(this);
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    projectID: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  load(){
    const { projectID } = this.props;
    load(projectID, this.loadError, this.loadSuccess);
  }

  componentDidMount(){
    this.load();
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  loadSuccess(data){
    const { customemojis } = data.snippets;
    const newFormData = {
      customemojis: customemojis
    };
    this.setState({
      formData: newFormData,
      loading: false,
      defaultSettings: data
    });
  }

  loadError(error){
    this.setState({
      loading: false,
      error: error.response.data
    });
  }

  update(event) {
    const { formData, defaultSettings } = this.state;
    const { projectID } = this.props;
    defaultSettings.snippets.customemojis = formData.customemojis;
    this.setState({saving: true});
    update(projectID, defaultSettings, this.updateError, this.updateSuccess);
    event.preventDefault();
  }

  updateSuccess() {
    this.setState({
      saving: false,
      error: false
    });
  }

  updateError(error) {
    this.setState({
      error: error.response.data,
      saving: false
    });
  }

  errorModalToggle(event) {
    event.preventDefault();
    const { errorModalIsOpen } = this.state;
    this.setState({
      errorModalIsOpen: !errorModalIsOpen,
      // error: false
    });
  }

  changeData(target, isValid = null) {
    const newData = Object.assign({}, this.state.formData);
    newData[target.name] = target.value;
    newData.error = this.validate(newData);
    this.setState({
      formData: newData,
    });
  }

  validate(formData){
    // const {quality, formData} = this.state;
    let error = false;
    for (var field in formData) {
      if(field !== 'error'){


      }
    };
    return error;
  }

  render() {
    const styles = require('./SnippetSettingsForm.module.scss');

    const { formData, saving, loading, error } = this.state;
    const { t } = this.props;

    const cookies = new Cookies();



    return (
      <div className={styles.generalsettings}>
        {!cookies.get('authtoken') ?
          <div>Du bist nicht eingeloggt!</div>
          :
          <form onSubmit={this.update} className={styles.generalsettingsform}>
            {loading ?
              <Loader style={{'marginLeft': '10px'}}/>
              :
              <div>
                <h2 className={styles.formhead}>{t('components.snippetsettingscustomemojisform.head')}</h2>
                <div className={`${styles.row}`}>
                  <div className={styles['col-12']}>
                    <Input labeltop label={t('components.snippetsettingscustomemojisform.fields.regex')} type="text" size="xl" fullWidth holderstyles={styles.holderstyles} onChange={this.changeData} name="customemojis" value={formData.customemojis}/>
                  </div>
                </div>
                <Button style={{'float': 'right'}} disabled={formData.error ? true : false} onClick={this.update} size="m">{t('components.snippetsettingscustomemojisform.buttons.save')}</Button>
                {formData.error && <span className={styles.error}>{formData.error}</span>}
              </div>
            }
          </form>
        }
        {saving && <SaveModal title="Speichere" titleSaving={t('components.general.modals.titlesaving')} isOpen={saving}/>}
        {apiError(error, this)}
      </div>
    );
  }
});
