import React from 'react';
import Cookies from 'universal-cookie';
import VpContainer from '../../components/Container/VpContainer';
import Leftnav from '../../components/Htmlelements/Leftnav';
import Button from '../../components/Form/Button';
import ConfirmModal from '../../components/Htmlelements/Modals/ConfirmModal';
import del from '../../api/deleteuser.js';
import { withRouter } from "react-router";
import apiError from '../../utils/apierrors.js';
const navpoints_de = require('../../json/nav.usersettings.de.json');
const navpoints_en = require('../../json/nav.usersettings.en.json');
const navpoints = {
  de: navpoints_de,
  en: navpoints_en,
}


export default withRouter(class Deleteuser extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: false,
        confirmModalIsOpen: false,
        deleting: false
      };
      this.error = this.error.bind(this);
      this.success = this.success.bind(this);
      this.confirmModalToggle = this.confirmModalToggle.bind(this);
      this.deleteUser = this.deleteUser.bind(this);

      const cookies = new Cookies();
      this.language = cookies.get('language');
      if(!this.language) {
        this.language = 'de';
      }
    }

    static propTypes = {
    };

    confirmModalToggle(event) {
      event.preventDefault();
      const { confirmModalIsOpen } = this.state;
      this.setState({
        confirmModalIsOpen: !confirmModalIsOpen
      });
    }

    deleteUser() {
      this.setState({deleting: true});
      del(this.error, this.success);
    }

    success() {
      const cookies = new Cookies();
      cookies.remove('authtoken', { path: '/' });
      const { history } = this.props;
      history.push("/usersettings/deleteusersuccess");
    }

    error(error) {
      this.setState({
        error: error.response.data,
        confirmModalIsOpen: false
      });
    }

    render() {
        const styles = require('./Deleteuser.module.scss');
        const { confirmModalIsOpen, deleting, error } = this.state;
        const { t } = this.props;

        return (
          <div className={styles.usersettings}>
            <VpContainer>
              <div className={`${styles.row}`}>
                <div className={styles['col-12']}>
                  <h1>{t('containers.deleteuser.head')}</h1>
                </div>
              </div>
              <div className={`${styles.row}`}>
                <div className={styles['col-3'] + ' ' + styles['col-m-12']}>
                  <Leftnav navpoints={navpoints[this.language]} active={1} />
                </div>
                <div className={styles['col-9'] + ' ' + styles['col-m-12']}>
                  <p className={styles.bigger}>{t('containers.deleteuser.text')}</p>
                  <Button style={{'float': 'left'}} onClick={this.confirmModalToggle} size="l">{t('containers.deleteuser.buttons.delete')}</Button>
                </div>
              </div>
            </VpContainer>
            <ConfirmModal
              closeAction={this.confirmModalToggle}
              confirmAction={this.deleteUser}
              title={t('containers.deleteuser.confirmmodal.title')}
              titleSaving={t('components.general.modals.titlesaving')}
              text={t('containers.deleteuser.confirmmodal.text')}
              confirmLabel={t('containers.deleteuser.confirmmodal.confirmlabel')}
              canchelLabel={t('containers.deleteuser.confirmmodal.canchellabel')}
              isOpen={confirmModalIsOpen}
              saving={deleting}
            />
            {apiError(error, this)}
          </div>
        );
    }
});
