import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import { useTable, useSortBy, usePagination } from 'react-table';
import { ReactComponent as ArrowFilled } from '../../images/arrows/arrow_filled.svg';
import { withRouter } from "react-router";

const styles = require('./Table.module.scss');

const Table = ({ children, data, columns, idindex, rowclick, selectedID, match, sort, sorting, pageNo, changePage, t}) => {

  const memoData = React.useMemo(() => {return data;}, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: memoData,
      initialState: { pageIndex: pageNo, pageSize: 25},
    },
    useSortBy, usePagination
  );

  const tableStylesAdditional = {'maxWidth': '100%', 'overflow': 'auto'};


  console.log('page', page);

  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div className={styles['col-12']} style={tableStylesAdditional}>

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps([{style: column.style}, column.getSortByToggleProps()])} onClick={e => sort(e, column.id)}>
                      {column.render('Header')}
                      <span className={styles.sortArrow}>
                        {column.isSorted ? (column.isSortedDesc ? <ArrowFilled className={styles.desc} /> : <ArrowFilled className={styles.asc} />) : ''}
                        {sorting.key === column.id && <ArrowFilled className={styles[sorting.direction]} />}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                let theActRow = match.params.snippet;
                if(typeof theActRow === 'undefined') {
                  theActRow = selectedID;
                }
                return (
                  <tr className={String(theActRow) === String(row.values[idindex]) ? styles.selected : styles.notselected} {...row.getRowProps()}>
                    {row.cells.map((cell, u) => {
                      if(u < row.cells.length-1) {
                        return <td {...cell.getCellProps()} onClick={() => rowclick(cell.row.values[idindex], i)} title={cell.column.Header === 'Status' && cell.value !== 'Download' && t('components.savebar.fields.' + cell.value)} className={styles['icon' + cell.value]}>{cell.render('Cell')}</td>
                      }
                      return <td {...cell.getCellProps()} className={styles['icon' + cell.value]} title={cell.column.Header === 'Status' && cell.value !== 'Download' && t('components.savebar.fields.' + cell.value)}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.buttonBar}>
        {data.length > 25 ?
          <div className={styles.row}>
            <div className={styles['col-4']}>
              <div className={styles.pageSize}>
                Zeige:
                {[25, 50, 100].map(pageSizes => (
                  <span key={pageSizes}>
                    <button disabled={pageSizes === pageSize && 'disabled'} onClick={() => {setPageSize(Number(pageSizes))}}>
                      {pageSizes}
                    </button>
                  </span>
                ))}
              </div>
            </div>
            <div className={styles['col-4']}>
              <Pagination changePage={changePage} pageOptions={pageOptions} gotoPage={gotoPage} canPreviousPage={canPreviousPage} canNextPage={canNextPage} previousPage={previousPage} pageIndex={pageIndex} nextPage={nextPage} pageCount={pageCount} />
            </div>
            <div className={styles['col-4']}>
              <div className={styles.buttons}>
                {children}
              </div>
            </div>
          </div>
          :
          <div className={styles.row}>
            <div className={styles['col-12']}>
              <div className={styles.buttons}>
                {children}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.string.isRequired]),
  data: PropTypes.any,
  columns: PropTypes.any,
  idindex: PropTypes.string,
  rowclick: PropTypes.func,
  selectedID: PropTypes.string,
  match: PropTypes.object.isRequired,
  sort: PropTypes.func,
  sorting: PropTypes.object,
  pageNo: PropTypes.number,
  changePage: PropTypes.func,
  t: PropTypes.func
};

Table.defaultProps = {
  t: () => {},
  data: false,
  columns: false,
  idindex: 'ID',
  rowclick: (ID) => {alert(ID);},
  selectedID: '',
  sort: () => {},
  sorting: {
    key: 'Title',
    direction: 'asc',
  },
  pageNo: 0,
  changePage: () => {}
};

export default withRouter(Table);
