import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Tab.module.scss');

const Tab = ({ children, active, corporate, onClick }) => (
  <li className={active ? `${styles.tab} ${styles.active}` : `${styles.tab} ${styles.inactive}`} onClick={onClick}>
    <div className={styles.noshaddow}>
        <div className={styles.shaddow} />
    </div>
    <div className={corporate ? `${styles.corporate}` : ``}>
      {children}
    </div>
  </li>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func
};

Tab.defaultProps = {
  active: false,
  corporate: false,
  onClick: () => { alert('Clicked'); }
}

export default Tab;
