import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import Sectionhead from '../Htmlelements/Sectionhead';
import UploadModal from '../../components/Htmlelements/Modals/UploadModal';
import SaveModal from '../../components/Htmlelements/Modals/SaveModal';
import Button from '../Form/Button';
import ButtonIcon from '../Form/ButtonIcon';
import apiError from '../../utils/apierrors.js';

import Table from '../../components/Htmlelements/Table';

import { ReactComponent as Add } from '../../images/buttons/add.svg';
import { ReactComponent as Upload } from '../../images/buttons/upload.svg';
import { ReactComponent as Download } from '../../images/buttons/download.svg';

import {exporter, importer} from '../../api/snippets.js';


export default withRouter(class Snippets extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pageNo: 0,
      actSnippet: this.props.match.params.snippet,
      uploadModalIsOpen: false,
      uploadError: false,
      error: false,
      uploading: false
    };
    // this.openImport = this.openImport.bind(this);
    this.uploadModalToggle = this.uploadModalToggle.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.export = this.export.bind(this);
    this.importSuccess = this.importSuccess.bind(this);
    this.importError = this.importError.bind(this);
    this.exportSuccess = this.exportSuccess.bind(this);
    this.exportError = this.exportError.bind(this);
  }

  static propTypes = {
    snippets: PropTypes.array,
    changePage: PropTypes.func.isRequired,
    sort: PropTypes.func.isRequired,
    inputModalToggle: PropTypes.func.isRequired,
    sorting: PropTypes.object.isRequired,
    refresh: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    snippets: [],
  };

  // openImport() {
  //   this.uploadModalToggle();
  //   alert('Import');
  // }

  uploadModalToggle(event) {
    const { uploadModalIsOpen } = this.state;
    this.setState({
      uploadModalIsOpen: !uploadModalIsOpen
    });
  }

  onDrop(files) {
    // const { accessToken } = this.props;
    importer(files[0], this.importSuccess, this.importError);
    this.setState({
      uploading: true,
      uploadModalIsOpen: false
    });
  }

  importSuccess(error) {
    const { refresh } = this.props;
    refresh();
    this.setState({
      uploading: false
    });
  }

  importError(error) {
    this.setState({
      error: error.response.data,
      loading: false,
    });
  }

  export() {
    const { match } = this.props;
    exporter(match.params.id, this.exportSuccess, this.exportError);
  }

  exportSuccess(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'snippets.csv'); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  exportError(error) {
    this.setState({
      error: error.response.data,
      loading: false
    });
  }

  render() {
    const styles = require('./SnippetsMobile.module.scss');

    const { t, changePage, snippets, saving, sorting, sort, gotoSnippet, inputModalToggle, error } = this.props;
    let { uploadError, uploadModalIsOpen, pageNo, actSnippet, uploading } = this.state;

    const cols = [
      {
        Header: ' ',
        accessor: 'ID', // accessor is the "key" in the data
        sortType: 'basic',
      },
      {
        Header: t('components.snippets.table.title'),
        accessor: 'Title',
        sortType: 'basic',
      },
      {
        Header: t('components.snippets.table.qualitytitleshort'),
        accessor: 'qualitytitle',
        sortType: 'alphanumeric',
      },
      {
        Header: t('components.snippets.table.qualitydescriptionshort'),
        accessor: 'qualitydescription',
        sortType: 'alphanumeric',
      },
      {
        Header: '',
        accessor: 'Status',
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true
      },
    ];
    return (
      <div className={styles.snippets}>
        {snippets.length < 1 ?
          <div />
          :
          <div>
            <div className={styles.row}>
              <div className={styles['col-12']}>
                <Sectionhead>{t('components.snippets.head')}</Sectionhead>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles['col-12']}>
                <Table pageNo={pageNo} changePage={changePage} sort={sort} sorting={sorting} columns={cols} selectedID={actSnippet} data={snippets} rowclick={gotoSnippet}>
                  <div className={styles.hiddenSmallerM}>
                    <Button size="m" disabled={saving} onClick={this.uploadModalToggle}>{t('components.snippets.buttons.import')}</Button>
                    <Button size="m" disabled={saving} onClick={this.export}>{t('components.snippets.buttons.export')}</Button><Button disabled={saving} size="m" onClick={inputModalToggle}>{t('components.snippets.buttons.new')}</Button>
                  </div>
                  <div className={styles.hiddenBiggerM}>
                    <ButtonIcon size="s" disabled={saving} title={t('components.snippets.buttons.import')} onClick={this.uploadModalToggle}><Upload /></ButtonIcon>
                    <ButtonIcon size="s" disabled={saving} title={t('components.snippets.buttons.export')} onClick={this.export}><Download /></ButtonIcon>
                    <ButtonIcon size="s" disabled={saving} title={t('components.snippets.buttons.new')} onClick={this.uploadModalToggle}><Add /></ButtonIcon>
                  </div>
                </Table>
              </div>
            </div>
            <UploadModal onDrop={this.onDrop} saveError={uploadError} saving={saving} title={t('components.snippets.modals.uploadmodal.title')} text={t('components.snippets.modals.uploadmodal.text')} label={t('components.snippets.modals.uploadmodal.label')} isOpen={uploadModalIsOpen} closeAction={this.uploadModalToggle} confirmAction={this.addSnippet} confirmLabel={t('components.snippets.modals.uploadmodal.confirmlabel')} canchelLabel={t('components.snippets.modals.uploadmodal.canchellabel')} />
            <SaveModal title="CSV wird hochgeladen" titleSaving={t('components.general.modals.titlesaving')} text={t('components.snippets.modals.savemodal.text')} isOpen={uploading}/>
          </div>
        }
        {apiError(error, this)}
      </div>
    );
  }
});
