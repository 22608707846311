import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../Form/Input';
import CopyBar from '../SnippetInputs/CopyBar';
import { withRouter } from "react-router";

export default withRouter(class Title extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    warningInfo: PropTypes.object.isRequired,
    onLeave: PropTypes.func,
    titleInputRef: PropTypes.object.isRequired,
    label: PropTypes.string,
    t: PropTypes.func.isRequired,
    setGPT: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    projectState: PropTypes.object.isRequired,
    maxLength: PropTypes.object.isRequired,
  };
  static get defaultProps() {
    return {
      value: '',
      onLeave: () => {},
      label: '',
      setGPT: false,
    };
  }

  render() {
    const styles = require('./Title.module.scss');

    const { titleInputRef, setGPT, projectState, maxLength } = this.props
    const {loadingFromGpt, device, service} = projectState;

    return (
      <div className={`${styles.title}`}>
        <Input {...this.props} loadingFromGpt={loadingFromGpt === 'title' ? true : false } inputRef={titleInputRef} withprogress size="xl" fullWidth name="title"/>
        <CopyBar loadingFromGpt={loadingFromGpt === 'title' ? true : false } {...this.props} setGPT={() => setGPT('title', maxLength[device][service].title)} />
      </div>
    );
  }
});
