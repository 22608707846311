import React from 'react';
import PropTypes from 'prop-types';

const styles = require('./Hint.module.scss');

const Hint = ({ children }) => (
  <div className={styles.hint}>{children}</div>
);

Hint.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Hint;
