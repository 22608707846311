import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../Form/Input';
import Upload from '../../Form/Upload';

export default class InputModal extends React.Component {
    static propTypes = {
      saving: PropTypes.bool,
      t: PropTypes.func.isRequired,
      parent: PropTypes.object.isRequired,
      values: PropTypes.object.isRequired,
    };

    static defaultProps= {
      saving: false,
    };

    constructor(props) {
        super(props);
        this.state = {
          values: this.props.values,
        };
        this.changeData = this.changeData.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    changeData(target) {
      const { values } = this.props;

      values[target.name] = target.value;
      this.props.parent.setState({
        values
      });
      console.log('this.state.values', this.state.values); 
    }

    onDrop(files) {
      console.log('files[0]', files[0]);
      this.changeData({name: 'CSV', value: files[0]});
    }

    render() {
        const styles = require('./modal.scss');
        const { saving, saveError, t, values } = this.props;
        // const {  } = this.state;

        return (
          <>
            <div className={styles.row}>
              <div className={styles['col-12']}>
                <Input error={saveError} autoFocus fullWidth disabled={saving} label={t('containers.projects.inputmodal.fields.Name.label')} size="s" holderstyles={styles.holderstyles} onChange={this.changeData} name="Name" value={values.Name}/>
              </div>
            </div>
            <br/>
            <div className={styles.row}>
              <div className={styles['col-12']}>
                <Input error={false} fullWidth disabled={saving} label={t('containers.projects.inputmodal.fields.URLs.label')} size="s" holderstyles={styles.holderstyles} onChange={this.changeData} name="URLs" value={values.URLs}/>
              </div>
            </div>
            <br/>
            <div className={styles.row}>
              <div className={styles['col-12']}>
                <Upload onDrop={(f) => this.onDrop(f)} text={t('containers.projects.inputmodal.fields.CSV.label')}/>
              </div>
            </div>

          </>
        );
    }
}
