import Cookies from 'universal-cookie';
const axios = require('axios');

const login = (email, password, errorCallback, successCallback) => {
  axios.post('/v1/login/', {
    Email: email,
    Password: password
  })
  .then(function (response) {
    const cookies = new Cookies();
    cookies.set('authtoken', response.data.authtoken, { path: '/', maxAge: 60*60*24*30 });  // 30 Tage
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default login;
