import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

export default class Input extends Component {
  static propTypes = {
    label: PropTypes.string,
    showlabel: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onLeave: PropTypes.func,
    validators: PropTypes.array,
    showValidation: PropTypes.bool,
    labeltop: PropTypes.bool,
    type: PropTypes.string,
    data: PropTypes.object,
    withprogress: PropTypes.bool,
    size: PropTypes.string,
    fullWidth: PropTypes.bool,
    warningInfo: PropTypes.object,
    holderstyles: PropTypes.string,
    autoFocus: PropTypes.bool,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
  };
  static get defaultProps() {
    return {
      label: 'Foo',
      showlabel: false,
      name: 'foo',
      value: '',
      validators: [],
      showValidation: false,
      labeltop: false,
      type: 'text',
      data: {},
      withprogress: false,
      size: 's',
      fullWidth: false,
      warningInfo: {},
      holderstyles: '',
      autoFocus: false,
      onLeave: () => {},
      inputRef: React.createRef(),
      disabled: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      error: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validate = this.validate.bind(this);
    this.validators = {
      email: value => {
        if (!validator.isEmail(value)) {
          return `${value} ist keine gültige E-Mail-Adresse.`;
        }
      },
      float: value => {
        if (!validator.isFloat(String(value))) {
          return `${value} ist keine gültige Kommazahl.`;
        }
      },
      integer: value => {
        if (!validator.isInt(String(value))) {
          return `${value} ist keine gültige Ganzzahl.`;
        }
      },
      required: value => {
        if (!value.toString().trim().length) {
          return 'Dieses ist ein Pflichtfeld.';
        }
      },
      notzero: value => {
        if (value.toString() === '0') {
          return 'Darf nicht 0 sein!';
        }
      },
      zip: value => {
        if (value.toString().trim().length < 4) {
          return 'Mind. 4-Stellig!';
        }
        if (!validator.isInt(value, { min: 1, max: 99999, allow_leading_zeroes: true })) {
          return '4- bis 5-Stellig, nur Zahlen!';
        }
      },
      phone: value => {
        if (!validator.isWhitelisted(value, '1234567890 + ()')) {
          return 'Keine gültige Telefonnummer!';
        }
      },
      password: value => {
        if (typeof value === 'undefined' || !value.toString().trim().length) {
          return 'Passwort und Wiederholung dürfen nicht leer sein!';
        }
        if (this.props.data.password !== this.props.data.passwordwdh) {
          return 'Passwort und Wiederholung müssen übereinstimmen.';
        }
      }
    };
    this.inputtype = this.props.type;
    if (this.props.type === 'float' || this.props.type === 'integer') {
      this.inputtype = 'text';
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showValidation === true) {
      const error = this.validate(this.state.value);
      if (error) {
        return {
          error,
          value: props.value
        };
      } else {
        return {
          error: false,
          value: props.value
        };
      }
    } else {

      return {
        value: props.value,
        error: props.error
      };
    }
  }

  validate(value) {
    const errors = [];
    this.props.validators.forEach(actValidator => {
      const newError = this.validators[actValidator](value);
      if (newError) {
        errors.push(newError);
      }
    });
    if (errors.length > 0) {
      const error = errors.join(' ');
      return error;
    }
    return false;
  }

  handleBlur(event) {
    let { onLeave } = this.props;
    const error = this.validate(event.target.value);

    const changedTarget = {};
    changedTarget.value = event.target.value;
    if (this.props.type === 'float') {
      changedTarget.value = event.target.value.replace(',', '.');
    }
    changedTarget.name = event.target.name;

    onLeave(event.target);

    if (error) {
      this.setState({
        error: false,
        value: changedTarget.value
      });
      this.props.onChange(changedTarget);
    } else {
      this.setState({
        error: false,
        value: changedTarget.value
      });
      this.props.onChange(changedTarget);
    }
  }

  handleChange(event) {
    const changedTarget = {};
    if (this.props.validators.includes('zip')) {
      changedTarget.value = event.target.value.trim();
    } else {
      changedTarget.value = event.target.value;
    }
    if (this.props.type === 'float') {
      changedTarget.value = event.target.value.replace(',', '.');
    }
    changedTarget.name = event.target.name;
    this.setState({
      value: changedTarget.value
    });
    this.props.onChange(changedTarget);
  }

  render() {
    const styles = require('./Input.module.scss');
    const { holderstyles, size, name, label, showlabel, labeltop, type, withprogress, fullWidth, warningInfo, autoFocus, inputRef, disabled } = this.props;
    const widthStyle = {width: warningInfo.percentWarning + '%'}
    return (
      <div className={withprogress ? `${holderstyles} ${styles.row} ${styles.withprogress} ${styles.input}` : `${holderstyles} ${styles.row} ${styles.input}`}>
        {showlabel && (
          <div>
            <label htmlFor={name}>{label}:</label>
          </div>
        )}
        <div>
          {labeltop && (
            <label htmlFor={name} className={styles.labeltop}>
              {label}:
            </label>
          )}
          <input disabled={disabled} ref={inputRef} autoFocus={autoFocus} type={this.inputtype} className={fullWidth ? styles[size] + ' ' + styles.fullWidth : styles[size]} value={type === 'float' && typeof this.state.value !== 'undefined' ? String(this.state.value).replace('.', ',') : String(this.state.value)} name={name} onChange={this.handleChange} onBlur={this.handleBlur} placeholder={!showlabel ? label : ''} />
          { withprogress &&
            <div className={styles.progress}>
              <div style={widthStyle} className={styles.progressinner + ' ' + styles[warningInfo.warningLength]} />
            </div>
          }
          {this.state.error && <span className={`${styles.error}`}>{this.state.error}</span>}
        </div>
      </div>
    );
  }
}
