import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import Cookies from 'universal-cookie';
import Character from './Specialcharacters/Character';
import SimpleSelect from '../Htmlelements/SimpleSelect';
import Loader from '../Htmlelements/Loader';
import _ from 'underscore';
import {update, index} from '../../api/emojis.js';
import apiError from '../../utils/apierrors.js';
const groupsFromJson_de = require('../../json/specialcharacters.de.json');
const groupsFromJson_en = require('../../json/specialcharacters.en.json');

const groupsFromJson = {
  de: groupsFromJson_de,
  en: groupsFromJson_en,
}


// import { ReactComponent as ArrowFilled } from '../../images/arrows/arrow_filled.svg';
// import Button from '../Form/Button';
// import Input from '../Form/Input';
// import { withTranslation } from 'react-i18next';

export default withRouter(class Specialcharacters extends Component {

  static propTypes = {
    headline: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
    t: PropTypes.func.isRequired
  };
  static defaultProps = {
    headline: 'Headline',
    fieldName: ''
  }

  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.language = cookies.get('language');
    // const groups = groupsFromJson[this.language];
    const groups = [...groupsFromJson[this.language]];
    this.groupNames = [];
    groups.forEach((val) => {
      this.groupNames.push(val.name)
    })
    if(!cookies.get('authtoken')) {
      this.groupNames.pop();
      this.groupNames.pop();
    }
    this.state = {
      group: 0,
      loading: false,
      saving: false,
      error: false,
      hint: false,
      emojis: [],
      groups
    };
    this.changeGroup = this.changeGroup.bind(this);
    this.updateEmojis = this.updateEmojis.bind(this);
    this.updateEmojisSuccess = this.updateEmojisSuccess.bind(this);
    this.updateEmojisError = this.updateEmojisError.bind(this);
    this.loadEmojis = this.loadEmojis.bind(this);
    this.loadEmojisSuccess = this.loadEmojisSuccess.bind(this);
    this.loadEmojisError = this.loadEmojisError.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    if(cookies.get('authtoken')) {
      this.loadEmojis();
    }
  }

  changeGroup(index) {
    // const { edit } = this.state;
    this.setState({group: index});
  }

  loadEmojis(e) {
    this.setState({
      loading: true
    }, index(this.props.match.params.id, this.loadEmojisSuccess, this.loadEmojisError));
  }

  loadEmojisSuccess(data, custom) {
    const { groups } = this.state;
    if(!_.isNull(data)){
      this.setState(
        {
          loading: false,
          emojis: data.Emojis
        },
        () => {
          groups[groups.length -2].chars = data.Emojis.split('');
          groups[groups.length -1].chars = custom.split('');
        }
      );
    }
    else{
      this.setState(
        {
          loading: false,
        }
      );
    }
  }

  loadEmojisError(error) {
    this.setState({
      loading: false,
      error: error.response.data
    });
  }

  updateEmojis(e) {
      const { emojis, group, groups } = this.state;
      const cookies = new Cookies();
      if(cookies.get('authtoken')) {
        if(groups.length-2 === group) { // <<<----#104
          this.setState(
            {
              saving: true,
              loading: true
            },
            update(this.props.match.params.id, emojis.replace(e.target.innerHTML, ''), this.updateEmojisSuccess, this.updateEmojisError)
          );
        }
        else{
          this.setState(
            {
              saving: true,
              loading: false
            },
            update(this.props.match.params.id, emojis+e.target.innerHTML, this.updateEmojisSuccess, this.updateEmojisError)
          );
        }
      }
  }

  updateEmojisSuccess(data) {
    const { groups } = this.state;
    groups[groups.length -2].chars = data.Emojis.split('');
    console.log('data.Emojis', data.Emojis);
    this.setState(
      {
        saving: false,
        loading: false,
        emojis: data.Emojis,
        groups
      }
    );
  }

  updateEmojisError(error) {
    this.setState({
      saving: false,
      loading: false,
      error: error.response.data
    });
  }

  render() {
    const styles = require('./Specialcharacters.module.scss');
    const { group, error, loading, hint, groups, saving } = this.state;
    const { t, onClick, fieldName } = this.props;

    const cookies = new Cookies();

    return (
      <div className={styles.specialcharacters}>
        {loading ?
          <Loader style={{'marginLeft': '10px', 'marginTop' : '8px', 'display': 'inline-block'}} text={t('components.specialcharacters.loading')} />
          :
          <div>
            <div className={styles.characters}>
              {groups[group].chars.map((char, i) => {
                return (<Character key={i + char} onMouseEnter={() => {this.setState({hint: true})}} onMouseLeave={() => {this.setState({hint: false})}} onDoubleClick={this.updateEmojis} onClick={onClick} fieldName={fieldName} char={char} />)
              })}
            </div>
            <div className={styles.selector}>
              <SimpleSelect onClick={this.changeGroup} selected={group} values={this.groupNames}/>
            </div>
            {hint && cookies.get('authtoken') &&
              <div className={styles.hint}>
                {saving ?
                  <span>{t('components.specialcharacters.saving')}</span>
                  :
                  <span>{groups.length-2 !== group ? t('components.specialcharacters.savehint') : t('components.specialcharacters.deletehint')}</span>
                }
              </div>
            }
          </div>
        }
        {apiError(error, this)}
      </div>
    );
  }
})
