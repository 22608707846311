const axios = require('axios');

export function index(ID, successCallback, errorCallback){
  axios.get('/v1/project/' + ID + '/snippet/')
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function add(ID, URL, successCallback, errorCallback) {
  axios.post('/v1/project/' + ID + '/snippet/', {
    URL,
    Title: '',
    Description: '',
    Status: 'Download'
  })
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function exporter(ID, successCallback, errorCallback) {
  axios({
  url: '/v1/project/' + ID + '/export',
  method: 'GET',
  responseType: 'blob', // important
  })
  .then(function (response) {
    successCallback(response);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function del(projectID, snippetID, successCallback, errorCallback) {
  axios.delete('/v1/project/' + projectID + '/snippet/' + snippetID, {

  })
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function edit(projectID, snippetID, snippetData, successCallback, errorCallback) {
  axios.put('/v1/project/' + projectID + '/snippet/' + snippetID, snippetData)
  .then(function (response) {
    successCallback();
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export function importer(projectID, file, successCallback, errorCallback) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0,
    maxContentLength: -1,
    maxBodyLength: -1,
  };
  const fd = new FormData();


  fd.append('snippetscsv',file);

  axios.post('/v1/project/' + projectID, fd, config)
  .then(function (response) {
    successCallback(response.data);
  })
  .catch(function (error) {
    errorCallback(error);
  });
}

export default {index, add, exporter, importer, del, edit};
